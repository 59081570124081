import React from 'react';
import { TiTimes } from 'react-icons/ti';
import styles from './panel.module.css';
import RegionInfo from './region-info';


export default ({ region }) => (
  <div className={styles.panel}>
    <button className={styles.close}><TiTimes /></button>
    <RegionInfo region={region} />
  </div>
)
