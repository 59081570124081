import { graphql } from 'gatsby'
import get from 'lodash/get'
import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import MapSVG from '../components/mapSVG'
import Panel from '../components/panel'
import styles from './map.module.css'

class Map extends React.Component {

  // TODO(Grace): Update URL as well
  state = {
    regionSlug: null
  };

  onSelectRegion = (regionSlug) => {
    this.setState({ regionSlug });

    console.log('selected ' + regionSlug);
  };

  render() {
    const {regionSlug} = this.state;

    const regions = get(this, 'props.data.allContentfulRegion.edges').map(r => r.node);
    const selectedRegion = regions.find(r => r.slug === regionSlug);

    return (
      <Layout location={this.props.location} >
        <div className={styles.container}>
          <Helmet title="Journey East | Map" />
          {selectedRegion && <Panel region={selectedRegion} />}
          <MapSVG onSelectRegion={this.onSelectRegion} />
        </div>
      </Layout>
    )
  }
}

export default Map

export const pageQuery = graphql`
  query RegionsQuery {
    allContentfulRegion(
      limit: 24,
    ) {
      edges {
        node {
					slug,
          nameEnglish,
          nameChinese,
          shortDescription {
            shortDescription
          },
          umami,
          sweet,
          sour,
          bitter,
          spice,
          keyIngredients,
          keyTechniques,
          recipes {
            title
            slug
            tagline { tagline }
            photo {
              fluid(maxWidth: 600) {
                ...GatsbyContentfulFluid_tracedSVG
              }
            }
          },
          didYouKnow {
            json
          }
        }
      }
    }
  }
`
