import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import base from './base.css'
import styles from './mapSVG.module.css'

const TextLabels = () => (
  <g>
    <text x="1120" y="1120" className={styles.provinceLabel}>GUANGDONG</text>
  </g>
);

class MapSVG extends React.Component {

  static propTypes = {
    onSelectRegion: PropTypes.func.isRequired,
  };

  onRegionClick = (ev) => {
    this.props.onSelectRegion(ev.currentTarget.id);
  };

  render() {
    return (
      <svg className={styles.map} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1670 1511">
        <path fill="#eeeeee" id="heilongjiang" fillRule="evenodd" d="
          M 1611.99996191263,312.000018596649
          C 1610.33347040415,311.66667753458 1608.6664814949,311.333336472511 1606.99998998642,310.999995410442
          C 1599.2814244628,305.462249577045 1593.75759005547,294.079122066498 1583.00000536442,292.000005841255
          C 1574.667547822,298.666000306606 1566.33250379562,305.334024131298 1558.0000462532,312.000018596649
          C 1555.6669382453,310.666804671288 1553.33313387632,309.333214938641 1551.00002586842,308.000001013279
          C 1548.04248094559,298.292447865009 1541.33761817217,292.26028996706 1533.99996215105,286.999965071678
          C 1531.66685414314,288.33317899704 1529.33304977417,289.666768729687 1527.00004124641,290.999982655048
          C 1526.33342474699,294.666283369064 1525.66660928726,298.333711504936 1524.99999278784,302.000012218952
          C 1510.42535465956,300.159007489681 1509.67228990793,288.004272460938 1493.99998778105,286.999965071678
          C 1495.12978386879,280.77516835928 1496.28494739532,276.88233101368 1493.99998778105,271.999993085861
          C 1485.66753023863,272.999865949154 1477.33248621225,274.000114619732 1469.00002866983,274.999987483025
          C 1467.25435096025,268.045748829842 1464.9678991437,262.75115031004 1459.99995982647,258.999992311001
          C 1451.55280166864,263.869552791119 1439.78609031439,264.296920835972 1424.99995738268,264.000033080578
          C 1421.73452138901,257.734123051167 1409.99277955294,245.775333940983 1403.99999570847,242.999997138977
          C 1397.73473572731,240.098464250565 1395.50827044249,244.489546895027 1392.00000339746,238.999979555607
          C 1400.84090399742,234.802061855793 1398.31649565697,221.737422227859 1393.99995237589,215.0000243783
          C 1393.66669386625,215.0000243783 1393.33333587646,215.0000243783 1392.99997788668,215.0000243783
          C 1389.33373636007,216.666504204273 1385.66630107164,218.333510160446 1381.99996006489,219.99998998642
          C 1378.99904179573,215.194617390633 1374.36843967438,214.073960065842 1372.00001621246,209.000035583973
          C 1387.02510046959,203.159689724445 1398.28127968311,190.159087657928 1403.00002121925,173.999975681305
          C 1410.33150947094,175.195794463158 1410.42372757196,178.560772895813 1414.00003904104,183.000034034252
          C 1415.33317255974,182.000086009502 1416.66680347919,180.999912500381 1418.00003647804,179.999964475632
          C 1418.33329498768,169.001137495041 1418.66665297747,157.998928248882 1419.00001096725,147.000026106834
          C 1431.59290498495,140.376873672009 1422.87406653166,133.385956227779 1426.00003135204,118.00003015995
          C 1433.5776335001,80.7023596763611 1444.660120368,74.2187820672989 1410.00004160404,49.0000087022781
          C 1408.66680860519,49.0000087022781 1407.33317768574,49.0000087022781 1406.00004416704,49.0000087022781
          C 1403.57829934359,52.9782294631004 1400.88139241934,64.1777375340462 1396.99997532368,66.9999750852585
          C 1387.92211300135,70.5004696249962 1364.6531085372,69.14230209589 1357.99997544289,65.0000038743019
          C 1356.31418478489,54.8036757707596 1355.29232466221,43.2908947467804 1350.9999550581,36.0000079274178
          C 1343.00075602531,38.3330947160721 1334.99916946888,40.666933119297 1326.9999704361,43.0000199079514
          C 1326.00009542704,42.3334129452705 1324.99992197752,41.6665804982185 1324.00004696846,40.9999735355377
          C 1323.66668897867,40.000100672245 1323.33333098888,38.9999271631241 1322.9999729991,37.9999791383743
          C 1325.1880389452,28.1118881702423 1328.16846430302,24.1078118681908 1325.99999594688,13.0000007748604
          C 1336.33230298758,10.3335729241371 1346.66769391298,7.66639345884323 1357.00000095367,4.99996560811996
          C 1379.06459921598,1.29886513948441 1424.64610648155,24.5025598406792 1431.99997776747,36.0000079274178
          C 1444.80287438631,56.0166313648224 1458.43255054951,108.472413539886 1477.99999803305,118.00003015995
          C 1492.18596720695,121.867838740349 1508.38372349739,117.296368598938 1523.00004380941,117.000006973743
          C 1532.80590188503,129.622997879982 1552.97938245535,132.855541825294 1562.0000436902,146.000002920628
          C 1567.37246930599,153.82859480381 1568.04425877333,165.839470803738 1572.99996203184,173.000027656555
          C 1587.66522657871,170.666865706444 1602.33476877213,168.333102464676 1617.000033319,166.000015676022
          C 1619.99965834618,161.000501036644 1623.00027817488,155.999483168125 1626.00000268221,150.999968528748
          C 1631.25524067879,145.026962697506 1656.61999350786,134.969157159328 1664.00002807379,135.999996542931
          C 1664.99990308285,140.666170120239 1666.00007653236,145.333771765232 1666.99995154142,150.000020503998
          C 1663.66696852446,154.999535143375 1660.33299070597,160.000477850437 1657.000007689,164.999992489815
          C 1656.00013267994,179.665195345879 1654.99985975027,194.334832727909 1653.99998474121,209.000035583973
          C 1648.86581408978,227.640152096748 1639.37550705671,229.555641829967 1642.99996691942,253.000003516674
          C 1638.00049239397,253.000003516674 1632.99952566624,253.000003516674 1627.99995166063,253.000003516674
          C 1624.66696864367,250.333575665951 1621.33299082518,247.666396200657 1618.00000780821,244.999968349934
          C 1601.35011422634,254.899183213711 1610.00996094942,290.015292406082 1611.99996191263,312.000018596649
          Z" />
        <path fill="#eeeeee" id="innermongolia" fillRule="evenodd" d="
          M 1407.00001865625,59.0000150799751
          C 1442.45195943117,63.3005281686783 1420.33085644245,112.579160392284 1419.99998545647,140.000014126301
          C 1415.62882757187,141.206731319427 1414.40800714493,142.028170883656 1411.99999058247,144.999979734421
          C 1411.99999058247,152.665922224522 1411.99999058247,160.334119558334 1411.99999058247,167.999986886978
          C 1405.17704486847,167.182681202888 1402.67004555464,167.277008831501 1398.00004929304,170.000033259392
          C 1391.81119006872,190.004555702209 1378.18360298872,197.341366171837 1364.00002133846,209.000035583973
          C 1364.00002133846,210.333174347878 1364.00002133846,211.666764080524 1364.00002133846,212.999978005886
          C 1369.95520168543,218.626790165901 1375.07833003998,225.558856189251 1380.99998557568,231.000019550323
          C 1384.33296859264,230.000071525574 1387.66704589128,228.999898016453 1391.00002890825,228.00002515316
          C 1391.00002890825,229.333163917065 1391.00002890825,230.666828811169 1391.00002890825,231.999967575073
          C 1381.21725022793,242.09054350853 1378.41777926683,241.615447938442 1379.00003659725,261.999986708164
          C 1368.93075507879,261.354424953461 1359.13962006569,256.62256026268 1350.9999550581,260.000015497208
          C 1350.9999550581,260.33328139782 1350.9999550581,260.666697621346 1350.9999550581,260.999963521957
          C 1353.99967956543,268.332564949989 1357.00029939413,275.667421221733 1360.00002390146,283.000022649765
          C 1364.89792919159,303.360058784485 1354.38904488087,321.218120336533 1376.99998813868,325.999967396259
          C 1379.99971264601,323.000273644924 1383.00033247471,319.999677956104 1385.99995750189,316.999984204769
          C 1387.33319050074,317.333325266838 1388.66682142019,317.666666328907 1389.99995493889,318.000007390976
          C 1391.23012650013,322.044520556927 1410.3810505867,349.89980751276 1412.99996507168,351.00002092123
          C 1405.90504062176,372.500557005405 1389.41938877106,364.09239512682 1372.99999070168,371.000033676624
          C 1352.43535417318,379.651568353176 1339.8312188983,396.840993583202 1322.9999729991,409.000012814999
          C 1322.33335649967,409.000012814999 1321.6666405201,409.000012814999 1321.00002402067,409.000012814999
          C 1315.3339329958,403.33389121294 1309.66605132818,397.666116058826 1303.99996030331,391.999994456768
          C 1303.33344328403,391.999994456768 1302.66662782431,391.999994456768 1302.00001132488,391.999994456768
          C 1293.40970122814,407.302040338516 1296.78914147615,418.621731579304 1298.00001388788,435.999962389469
          C 1291.66730636358,434.666823625565 1285.33270871639,433.333233892918 1279.00000119209,432.000019967556
          C 1276.07259875536,422.644147276878 1278.3979473114,419.155377924442 1276.99995273352,409.000012814999
          C 1273.71352642775,405.011269450188 1257.25503265858,390.59455037117 1252.99996811152,387.999976873398
          C 1226.73661106825,396.131995558739 1207.47367233038,420.346912503242 1182.99996322393,423.999984800816
          C 1183.11904096603,417.708068907261 1182.91729521751,416.268727004528 1178.99996578693,413.999978423119
          C 1175.00036627054,412.666764497757 1170.99957299232,411.33317476511 1166.99997347593,410.000036001205
          C 1160.14380139112,421.608272075653 1156.77739304304,425.292686700821 1145.00003731251,432.000019967556
          C 1145.33647918701,447.089208602905 1150.54645371437,460.370689034462 1153.00003218651,471.000022292137
          C 1151.66679918766,471.000022292137 1150.3331682682,471.000022292137 1149.00003474951,471.000022292137
          C 1144.11217695475,467.168667018414 1141.76265472174,463.566328704357 1134.99999397993,464.000010311604
          C 1124.42356264591,481.756904542446 1109.82485032082,475.920692563057 1094.00004512072,485.999994277954
          C 1093.66668713093,489.66629499197 1093.33332914114,493.333723127842 1092.99997115135,497.000023841858
          C 1088.33375513554,499.333110630512 1083.66624587774,501.66687387228 1079.00002986193,504.000035822392
          C 1078.81081861258,497.84055441618 1078.50243014097,497.562757670879 1075.00003242493,494.999977469444
          C 1063.42491984367,500.955621004105 1039.83857369423,505.181348443031 1029.99998664856,513.000019013882
          C 1024.5703599453,517.314887940884 1024.73330843449,525.915989279747 1021.00001728535,532.000008583069
          C 1014.00069326162,539.999216973782 1006.99928015471,548.00083053112 999.999956130981,555.999963760376
          C 1004.72566127777,563.639374256134 1005.47594058514,564.921854197979 999.999956130981,572.000034093857
          C 994.979292333126,572.969316244125 989.082705795765,575.164932727814 983.99996638298,572.999982118607
          C 973.963015913963,567.762055337429 967.669304609299,555.68368434906 959.000007271767,549.999974966049
          C 954.000532746315,548.333495140076 948.999466538429,546.666489183903 943.999992012978,545.000009357929
          C 944.532210826874,530.384413063526 951.297657012939,527.638314068317 947.999989449978,513.000019013882
          C 947.000114440918,512.000070989132 945.999940991402,510.999897480011 944.99996650219,510.000024616718
          C 943.00021648407,510.000024616718 940.999770104885,510.000024616718 939.000020086765,510.000024616718
          C 914.17793148756,522.471264541149 915.582790195942,540.704607248306 908.999989569187,566.99999332428
          C 896.888778448105,568.192505002022 887.006420135498,569.640039503574 878.999959051609,574.000005304813
          C 867.208775579929,571.207381367683 856.258000433445,563.626822292805 850.000002503395,555.000015735626
          C 854.517694652081,545.283593535423 873.829080224037,525.119428157806 868.000040709972,510.999972641468
          C 861.218578219414,494.11269646883 845.342739701271,507.970965921879 833.000038266182,510.000024616718
          C 827.226210236549,504.924822390079 816.163320958614,496.11642575264 803.000007748604,501.999989449978
          C 801.666774749756,502.666596412659 800.333243310452,503.333428859711 799.000010311604,504.000035822392
          C 799.000010311604,505.333174586296 799.000010311604,506.666764318943 799.000010311604,507.999978244305
          C 803.385990738869,511.903789162636 806.567167043686,513.820406317711 807.999979674816,520.999979019165
          C 807.66672116518,521.333320081234 807.33326369524,521.66673630476 807.000005185604,522.000002205372
          C 797.960144281387,521.092352449894 794.133839190006,519.70344388485 783.999995052814,520.000030994415
          C 783.788301289082,516.636555790901 777.317117393017,496.854285776615 776.000000178814,494.000029444695
          C 766.667568147182,489.333780705929 757.332450151443,484.666179060936 748.000018119812,480.000005483627
          C 750.831621170044,473.190377473831 763.593830406666,461.751630485058 768.999979794025,455.999975144863
          C 768.567141652107,448.9741076231 766.939546883106,448.907213926315 764.000007867813,445.000020742416
          C 755.334192335606,442.666933953762 746.665790319443,440.333095550537 737.999974787235,438.000008761883
          C 728.024801492691,440.022002279758 720.492960214615,447.026523947716 708.000043749809,448.00001513958
          C 708.591652214527,436.712417840958 699.878882050514,428.163027584553 694.000002980232,419.999967217445
          C 696.396678805351,411.456589877605 699.094381570816,405.42202681303 698.999974906445,391.999994456768
          C 710.435317873955,393.816045582294 721.479305922985,399.824377298355 734.999951839447,402.000000834465
          C 754.594060003757,405.152873635292 771.881820321083,391.30467581749 792.999964416027,397.999983251095
          C 798.999413430691,403.999370753765 805.000553607941,410.000637292862 811.000002622604,416.000024795532
          C 820.079556107521,421.544610321522 829.619503736496,419.370940983295 841.000033140182,423.999984800816
          C 846.724220573902,426.328336417675 857.561488866806,437.506648957729 862.999969303608,442.000026345253
          C 868.666060328484,440.333471357822 874.333941996098,438.666465401649 880.000033020973,436.999985575676
          C 886.246988654137,451.421590149403 931.246139109135,432.251810848713 942.000043034554,429.000025570393
          C 956.998466610909,425.333724856377 972.001466274261,421.666296720505 986.999989330769,417.999996006489
          C 995.938181519508,416.491204917431 1007.16183072329,420.769770860672 1013.00002241135,419.999967217445
          C 1013.00002241135,419.999967217445 1053.78360193968,396.289834618568 1053.99997127056,396.000012040138
          C 1059.94659632444,388.036129534245 1062.1675902009,376.966049492359 1069.99996101856,371.000033676624
          C 1078.36017352343,364.631904065609 1093.41927999258,364.208369255066 1100.99996602535,357.000009715557
          C 1100.99996602535,356.666668653488 1100.99996602535,356.333327591419 1100.99996602535,355.99998652935
          C 1096.1800531745,351.758550345898 1078.09147763252,348.263016462326 1075.00003242493,342.99998575449
          C 1068.71398109198,332.298497498035 1087.50727403164,321.912311553955 1095.99999409914,321.000001788139
          C 1121.30814272165,336.211478114128 1146.05542224646,335.786515235901 1154.99998116493,306.000029802322
          C 1173.76760822535,306.611919224262 1197.2234364152,306.807639658451 1209.99997079372,299.000017821789
          C 1209.3333542943,293.667237281799 1208.66663831472,288.332803189754 1208.0000218153,283.000022649765
          C 1217.78658074141,275.812332510948 1228.40519165993,259.784527599812 1240.99997580051,257.000021100044
          C 1262.93345922232,252.150754213333 1281.0644133091,272.570994675159 1305.00003427267,255.999997913837
          C 1305.00003427267,254.000177025795 1305.00003427267,251.999830007553 1305.00003427267,250.000009119511
          C 1295.47749567032,230.104320466518 1265.98769885302,195.330722033978 1233.99995541573,199.999977231026
          C 1229.00048089027,202.999670982361 1223.99951416254,206.000266671181 1219.00003963709,209.000035583973
          C 1211.1993035078,211.84211575985 1193.79475349188,211.70960611105 1186.99996066093,212.999978005886
          C 1186.99996066093,212.000105142593 1186.99996066093,210.999931633472 1186.99996066093,209.999983608723
          C 1191.89269298315,196.125554442406 1194.69604367018,157.90151900053 1207.00004732609,150.999968528748
          C 1212.84570002556,149.94139456749 1216.42111617327,156.83174598217 1224.99998605251,157.999980509281
          C 1238.3909098506,149.59016507864 1257.49587410688,142.303186655045 1267.00000888109,130.000007748604
          C 1265.33351737261,127.333579897881 1263.66652846336,124.666400432587 1262.00003695488,121.999972581863
          C 1267.18902117014,111.920746028423 1263.8622059226,94.9126853942871 1270.00003182888,83.9999934434891
          C 1278.38183152676,69.0974307060242 1294.30323195457,62.5423745512962 1302.00001132488,44.9999911189079
          C 1297.55513864756,36.9311080574989 1292.39729171991,37.1838008761406 1280.99995017052,37.9999791383743
          C 1280.99995017052,37.0001062750816 1280.99995017052,35.9999327659607 1280.99995017052,34.9999847412109
          C 1281.99992465973,33.3335049152374 1283.00009810925,31.6664989590645 1283.99997311831,30.000019133091
          C 1295.49331301451,25.7146886587143 1306.13599812984,18.5424817800522 1319.99995005131,17.0000183582306
          C 1320.66656655073,17.6666253209114 1321.33338201046,18.3333826065063 1321.99999850988,18.9999895691872
          C 1320.76664358377,33.7498993873596 1314.6687092185,36.6130247712135 1322.9999729991,47.9999855160713
          C 1325.66643899679,48.6665924787521 1328.33360135555,49.3334249258041 1330.9999678731,50.000031888485
          C 1336.7281345129,47.0530263185501 1340.1044908762,44.0476954579353 1348.00003159046,43.0000199079514
          C 1348.00003159046,43.6666268706322 1348.00003159046,44.3333841562271 1348.00003159046,44.9999911189079
          C 1352.22027808428,56.551630616188 1348.32473480701,67.8837986588478 1359.00004941225,72.9999638795853
          C 1367.29470449686,77.7043946385384 1391.56985121965,75.1439444422722 1402.00004673004,72.9999638795853
          C 1403.33318024874,71.0002181529999 1404.66681116819,68.9997959733009 1406.00004416704,66.9999750852585
          C 1406.33330267668,64.3336223959923 1406.66666066647,61.6663677692413 1407.00001865625,59.0000150799751
          Z" />
        <path fill="#eeeeee" id="xinjiang" fillRule="evenodd" d="
          M 456.9999807477,115.999983787537
          C 465.665796279907,116.666590750217 474.33419829607,117.333423197269 483.000013828278,118.00003015995
          C 482.723260045052,132.902367413044 483.838532030582,142.851188838482 491.99998319149,148.999997317791
          C 494.333091199398,149.999870181084 496.666895568371,151.000118851662 499.000003576279,151.999991714954
          C 502.0832914114,157.764950633049 499.298444032669,162.615044295788 503.000001013279,167.999986886978
          C 508.666092038155,168.666593849659 514.333874225616,169.333426296711 519.999965250492,170.000033259392
          C 523.880487024784,187.080775022507 536.619019985199,207.11558753252 539.999952435493,225.999978780746
          C 538.000202417374,230.999493420124 535.999756038189,236.000511288643 534.000006020069,241.00002592802
          C 527.44734787941,247.30359184742 522.188031196594,243.345213711262 518.000016272068,253.000003516674
          C 518.000016272068,256.66630423069 518.000016272068,260.333732366562 518.000016272068,264.000033080578
          C 530.726810574532,287.811934292316 586.294732391834,284.468076229095 615.000028729439,295.000000238419
          C 627.624060034752,299.631749868393 626.794495046139,309.91902333498 634.000041425228,319.999978601933
          C 636.246601700783,323.143155574799 641.502138137817,322.904893755913 643.999985277653,325.999967396259
          C 645.333218276501,334.332516849041 646.666749715805,342.667471468449 647.999982714653,351.00002092123
          C 650.666349232197,356.33280146122 653.33361107111,361.667235553265 655.999977588654,367.000016093254
          C 648.333937585354,377.911054491997 642.706345379353,378.048675119877 643.999985277653,397.000035226345
          C 620.628516256809,394.651991307735 593.378714025021,403.973064243793 582.999949753284,417.999996006489
          C 577.912833213806,424.875390291214 577.151412129402,434.226152002811 569.00000846386,438.000008761883
          C 559.084921181202,442.590344429016 551.445541858673,434.938908100128 543.999949872494,439.00003194809
          C 541.520208120346,456.913712978363 541.924395978451,465.860256373882 545.000023841858,481.999976694584
          C 544.66666585207,482.333317756653 544.333307862282,482.666733980179 543.999949872494,482.999999880791
          C 521.333297729492,483.976347208023 481.492690563202,484.105474591255 467.999998569489,496.000000655651
          C 466.000248551369,495.000127792358 463.999802172184,493.99987912178 461.999952673912,493.000006258488
          C 460.666819155216,493.99987912178 459.33318823576,495.000127792358 457.999955236912,496.000000655651
          C 458.666571736336,504.332474946976 459.333387196064,512.66750472784 460.000003695488,520.999979019165
          C 463.456839501858,530.190120697021 474.010688841343,533.600496649742 476.999967932701,545.000009357929
          C 469.016785204411,546.486928462982 466.404137969017,548.33364546299 461.999952673912,552.999969363213
          C 463.218385577202,564.4787774086 470.270931482315,564.916818380356 467.999998569489,578.000022888184
          C 466.666765570641,578.000022888184 465.333234131336,578.000022888184 464.000001132488,578.000022888184
          C 459.333785116673,573.333774149418 454.666176378727,568.666172504425 449.999960362911,563.999998927116
          C 439.159508705139,564.658939421177 437.265008687973,571.00309252739 431.000047147274,575.99997651577
          C 411.087602496147,574.788899958134 414.666898369789,563.25808018446 402.99996560812,555.999963760376
          C 397.426988005638,553.175396203995 341.708652198315,548.948315858841 331.999986231327,552.000021338463
          C 325.564018309116,554.02299195528 324.832043349743,560.776324033737 318.000044941902,562.99997574091
          C 312.907954394817,564.631806135178 305.878288924694,563.950918495655 301.999955713749,562.00002771616
          C 300.000205695629,559.333599865437 297.999759316444,556.666420400143 296.000009298325,553.999992549419
          C 278.003253936768,558.342671215534 242.601252198219,568.154623627663 225.000029921532,558.00001013279
          C 214.028562903404,529.45421487093 193.556144475937,552.276089370251 167.000017344952,546.000032544136
          C 159.185552954674,544.153165221214 156.819517016411,537.99962157011 147.00003015995,536.999974191189
          C 143.248434662819,542.898194372654 123.170455038548,557.278685629368 116.999999642372,561.000004529953
          C 99.1469931006432,566.925207674503 87.3933136463165,560.266729354858 78.9999742507935,550.999998152256
          C 79.1995314359665,539.136513769627 83.3948084115982,532.713741779327 81.9999971985817,523.000025391579
          C 63.1131929159164,515.018705427647 62.3702751398087,501.773152172565 49.0000432133675,494.999977469444
          C 42.3339776992798,493.666763544083 35.6660220623016,492.333173811436 28.999956548214,491.000035047531
          C 30.7259371876717,474.148009598255 38.5409984588623,455.591547787189 26.0000330805779,439.999979972839
          C 20.6786407828331,433.384193360806 10.9065064787865,436.904605686665 7.99999487400055,426.999979197979
          C 16.6362648010254,427.379845201969 20.1091169118881,427.919429302216 24.9999591112137,425.000007987022
          C 28.3542308807373,396.710889101028 38.624860227108,365.638691782951 2.0000484585762,367.999964118004
          C 1.69126206636429,359.203893959522 2.26526254415512,351.433326721191 4.99997192621231,345.999980151653
          C 16.6454169750214,344.62332290411 12.5640447735786,339.245670974255 19.0000126957893,335.000025749207
          C 35.6650272607803,333.000204861164 52.3350158333778,330.999782681465 69.0000303983688,329.00003695488
          C 69.0790176391602,334.003459990025 68.8169869184494,333.035154938698 70.9999793767929,336.999996960163
          C 78.5619631409645,336.242143988609 85.5014001131058,340.5936165452 92.0000405311584,338.99996817112
          C 99.332623064518,334.333794593811 106.667394161224,329.666192948818 113.999976694584,325.00001937151
          C 125.665516734123,326.666499197483 137.334538578987,328.333505153656 148.999979138374,329.99998497963
          C 156.539380908012,328.333129346371 163.506174921989,321.795736134052 172.999963760376,319.999978601933
          C 190.634909808636,316.664388298988 219.044053733349,316.171704947948 226.999978899956,303.000035405159
          C 221.905202388763,285.442694842815 237.803224980831,280.546602368355 245.999991595745,273.000016272068
          C 245.842415034771,252.027489423752 247.173658430576,234.036166608334 245.999991595745,216.999995589256
          C 264.695992946625,218.440915882587 310.771717607975,222.080083310604 316.999970972538,218.000018775463
          C 320.496797800064,189.7090960145 327.695977449417,178.145205199718 342.000029563904,161.999998092651
          C 351.746100068092,163.8354408741 354.577703118324,170.490311443806 362.000016748905,173.999975681305
          C 379.20600438118,182.136053085327 400.565686285496,177.25649613142 413.999983429909,170.000033259392
          C 416.229632079601,154.559840619564 416.323839783669,137.905564963818 426.999950230122,131.000030934811
          C 441.195568978786,125.99420273304 447.335782408714,131.198908150196 456.9999807477,121.000024557114
          C 456.9999807477,119.333469569683 456.9999807477,117.66646361351 456.9999807477,115.999983787537
          Z" />
        <path fill="#eeeeee" id="jilin" fillRule="evenodd" d="
          M 1461.00003379583,270.000021874905
          C 1461.33329230547,273.332981526852 1461.66665029526,276.666993439198 1462.00000828505,280.000028252602
          C 1462.99988329411,280.333294153214 1464.00005674362,280.66671037674 1465.00003123283,280.999976277351
          C 1472.99923026562,280.333369314671 1481.00081682205,279.666612029076 1489.00001585484,279.000005066395
          C 1487.97686249018,285.583472251892 1488.2276519537,288.573094367981 1490.99996483326,293.000029027462
          C 1502.84675770998,295.95334815979 1532.68602830172,324.94424957037 1532.00001317263,296.000023424625
          C 1533.33324617147,296.000023424625 1534.66677761078,296.000023424625 1536.00001060963,296.000023424625
          C 1564.03680032492,336.239137530327 1554.9524717927,309.566140294075 1586.00002831221,299.999965846539
          C 1592.61406570673,309.291650652885 1607.15537798405,313.788560628891 1613.000035882,322.999972999096
          C 1614.50109189749,328.903454482555 1608.4956740737,341.000991642475 1606.00001549721,348.999974548817
          C 1605.66665750742,348.999974548817 1605.33329951763,348.999974548817 1605.000041008,348.999974548817
          C 1595.98047405481,346.942279338837 1583.12952852249,342.592610657215 1572.99996203184,344.000008940697
          C 1568.78090929985,357.291485846043 1566.35468786955,370.288329839706 1555.99999779463,377.000022470951
          C 1548.33405727148,377.999895334244 1540.66592818499,379.000068843365 1532.99998766184,380.000016868114
          C 1532.6667291522,380.666623830795 1532.33327168226,381.33338111639 1532.00001317263,381.999988079071
          C 1535.71460205317,389.941322147846 1544.0314412117,396.243685483932 1539.00003355742,406.999966442585
          C 1519.65313357115,411.931459963322 1515.93327224255,398.130989670753 1498.99995970726,396.000012040138
          C 1494.2473949194,399.498176574707 1491.0206567049,403.72119820118 1489.00001585484,410.000036001205
          C 1487.66678285599,410.000036001205 1486.33315193653,410.000036001205 1485.00001841784,410.000036001205
          C 1481.33367741108,407.000267088413 1477.6663416028,403.999671399593 1474.00000059605,400.999977648258
          C 1468.58101826906,391.964593410492 1460.86255222559,358.302783250809 1454.00001341105,354.000015318394
          C 1453.00013840199,353.666674256325 1451.99986547232,353.333333194256 1450.99999046326,352.999992132187
          C 1448.66688245535,356.333026945591 1446.33307808638,359.66696369648 1443.99997007847,362.999998509884
          C 1442.66683655977,362.999998509884 1441.33320564032,362.999998509884 1439.99997264147,362.999998509884
          C 1433.13206189871,353.956947803497 1418.13542896509,349.836972534657 1414.00003904104,345.000032126904
          C 1413.66668105125,342.000263214111 1413.33332306147,338.999667525291 1412.99996507168,335.999973773956
          C 1411.59729492664,333.559932231903 1391.45156931877,309.758929431438 1388.99998044968,308.000001013279
          C 1381.16183978319,310.26641958952 1380.54436647892,315.791613459587 1372.99999070168,318.000007390976
          C 1371.66675770283,317.000134527683 1370.33322626352,315.999885857105 1368.99999326468,315.000012993813
          C 1367.10429948568,306.254301011562 1368.68991363049,294.800296247005 1367.00004428625,284.999993860722
          C 1364.33357828856,278.667265295982 1361.66641592979,272.332732856274 1359.00004941225,266.000004291534
          C 1359.33330792189,265.666663229465 1359.66666591167,265.333322167397 1360.00002390146,264.999981105328
          C 1365.99937343597,265.999929130077 1372.00061309338,267.000102639198 1377.99996262789,267.999975502491
          C 1381.89092981815,266.995141983032 1381.00356686115,267.611841738224 1383.00003403425,264.999981105328
          C 1384.33316755295,260.333807528019 1385.6667984724,255.666205883026 1387.00003147125,251.000032305717
          C 1392.39066195488,249.688164234161 1396.0788885951,249.207581877708 1402.00004673004,250.000009119511
          C 1409.542930305,256.754919588566 1416.89272290468,263.794165849686 1423.99998289347,270.999969899654
          C 1436.33213943243,270.666703999043 1448.66787725687,270.333287775517 1461.00003379583,270.000021874905
          Z" />
        <path fill="#eeeeee" id="liaoning" fillRule="evenodd" d="
          M 1368.99999326468,500.000018239021
          C 1369.46735101938,468.773514449596 1404.91978919506,466.13376891613 1392.00000339746,442.999974370003
          C 1382.29859948158,442.342086136341 1375.44411855936,439.557128667831 1368.99999326468,435.999962389469
          C 1343.89339143038,443.633735775948 1343.61554336548,459.496561288834 1325.00002145767,469.99999910593
          C 1323.66678845882,469.666733205318 1322.33315753937,469.333316981792 1321.00002402067,468.999975919724
          C 1317.42958188057,458.584101200104 1308.73322594166,450.599624454975 1294.99999094009,449.999986350536
          C 1296.14122724533,442.606128334999 1302.05780929327,439.061288535595 1306.00000876188,433.999991178513
          C 1301.75538963079,423.247393131256 1301.54429274797,417.157360911369 1303.99996030331,404.999995231628
          C 1305.33319330215,404.999995231628 1306.66682422161,404.999995231628 1307.99995774031,404.999995231628
          C 1308.33331573009,404.999995231628 1308.66667371988,404.999995231628 1309.00003170967,404.999995231628
          C 1313.21898496151,410.573593080044 1316.35101807117,415.149873554707 1319.99995005131,422.000013589859
          C 1320.99992454052,422.000013589859 1322.00009799004,422.000013589859 1322.9999729991,422.000013589859
          C 1334.47998255491,403.762311518192 1354.0190781951,386.38783878088 1373.99996519089,377.000022470951
          C 1380.60574573278,373.8963804245 1386.38922333717,377.517058134079 1392.00000339746,377.000022470951
          C 1402.78285604715,376.006312847137 1413.7738211751,361.691212058067 1419.99998545647,354.999963343143
          C 1421.33321845531,355.666570305824 1422.66684937477,356.333402752876 1423.99998289347,357.000009715557
          C 1429.99943190813,361.666183292866 1436.00057208538,366.333784937859 1442.00002110004,371.000033676624
          C 1445.66626262665,368.666871726513 1449.33369791508,366.333108484745 1453.00003892183,364.000021696091
          C 1453.00003892183,364.666628658772 1453.00003892183,365.333385944366 1453.00003892183,365.999992907047
          C 1458.98207890987,379.980775535107 1461.09692746401,389.920878231525 1467.99995470047,403.000024020672
          C 1472.77241551876,412.042097628117 1482.85045129061,411.001186609268 1485.00001841784,423.999984800816
          C 1471.29433941841,441.255476951599 1455.52603894472,448.9081158638 1445.00004404783,469.99999910593
          C 1428.00167149305,473.999565720558 1410.99832493067,478.000410079956 1393.99995237589,481.999976694584
          C 1387.33398634195,487.999364197254 1380.66603070498,494.000630736351 1373.99996519089,500.000018239021
          C 1372.3334736824,500.000018239021 1370.66648477316,500.000018239021 1368.99999326468,500.000018239021
          Z" />
        <path fill="#eeeeee" id="gansu" fillRule="evenodd" d="
          M 942.000043034554,595.999989271164
          C 945.999642550945,595.333382308483 950.000435829163,594.666625022888 954.000035345554,594.000018060207
          C 962.582784950733,604.341708421707 978.751294076443,618.899580717087 994.000009715557,621.999990820885
          C 994.000009715557,624.666418671608 994.000009715557,627.333598136902 994.000009715557,630.000025987625
          C 987.594482719898,641.847350656986 986.149533510208,642.60828524828 987.999963819981,657.999998748302
          C 967.587134003639,657.746629476547 967.178767979145,666.999831616879 954.000035345554,669.999976336956
          C 952.666802346706,668.000230610371 951.33317142725,665.999808430672 950.000037908554,663.999987542629
          C 944.00058889389,664.66659450531 937.99944871664,665.333426952362 931.999999701977,666.000033915043
          C 927.84978723526,671.631205439568 924.069342494011,675.884892940521 920.999981880188,682.999977111816
          C 924.190409839153,689.646880567074 927.974435865879,689.61959695816 932.999974191189,694.00000667572
          C 929.601831674576,702.013119935989 928.4004098773,705.222514152527 928.999976754189,717.000013828278
          C 918.540235638618,715.718210339546 911.796277165413,717.466916799545 904.999992132187,721.999979436398
          C 906.017972528934,727.015653789043 906.840872347355,728.82787168026 905.999966621399,734.000032186508
          C 899.000742077827,739.999419689178 891.999328970909,746.000611066818 885.000004947186,751.999998569489
          C 880.840640306473,750.927519738674 864.126780986786,744.792089998722 861.999994814396,740.999969005585
          C 864.741468846798,736.74169665575 869.497316479683,729.099655508995 868.000040709972,724.000025808811
          C 861.867984652519,706.12362486124 839.919380247593,711.732999563217 825.000043392181,704.000013053417
          C 823.294953584671,696.967381000519 821.620603144169,692.269639611244 815.999974548817,688.999965906143
          C 809.371910452843,690.999711632729 803.103765547276,692.390724718571 799.999984800816,698.00002425909
          C 797.032591342926,705.723164618015 802.927785158157,713.595425307751 803.999982237816,721.999979436398
          C 803.333365738392,721.999979436398 802.666649758816,721.999979436398 802.000033259392,721.999979436398
          C 795.849573373795,725.849749267101 790.290622472763,723.203615009785 786.000043511391,720.000008225441
          C 781.372724235058,706.893428504467 778.703771233559,706.119415819645 765.999956846237,702.99998986721
          C 764.85076212883,694.185279667377 760.802118897438,693.626003265381 759.000035941601,685.99997150898
          C 767.111548066139,680.422540426254 783.566659510136,695.534277498722 792.999964416027,695.999977886677
          C 796.666305422783,691.333804309368 800.333740711212,686.666202664375 803.999982237816,682.000029087067
          C 803.999982237816,681.000081062317 803.999982237816,679.999907553196 803.999982237816,679.000034689903
          C 800.08474189043,676.517000794411 797.380373954773,675.379582464695 795.999987363815,669.999976336956
          C 802.825718522072,667.119939625263 807.089338362217,662.078560054302 807.000005185604,652.000009953976
          C 809.333113193512,651.666668891907 811.666917562485,651.333327829838 814.000025570393,650.999986767769
          C 822.839632928371,646.686771392822 828.250656843185,633.409950971603 831.999964296818,623.999962031841
          C 827.333847761154,615.000880777836 822.666239023209,605.999093711376 818.000023007393,597.000012457371
          C 816.490411698818,590.909153461456 818.806608080864,586.158348083496 815.999974548817,581.000017285347
          C 810.281755924225,579.708968937397 804.599748075008,577.561230301857 802.000033259392,572.999982118607
          C 800.666800260544,568.66707444191 799.333169341087,564.332889020443 798.000035822392,559.999981343746
          C 775.226541996002,557.434194684029 761.377412617207,540.471080601215 748.999992609024,526.999967813492
          C 745.000393092632,527.999915838242 740.999599814415,529.000089347363 737.000000298023,529.999962210655
          C 713.639374613762,492.89891409874 710.461978554726,515.341824531555 679.999962210655,510.999972641468
          C 677.473962187767,503.198814690113 671.847066342831,495.670192182064 665.000046432018,491.999983072281
          C 654.324731826782,498.691306948662 652.354726374149,515.177521586418 646.000033736229,526.000019788742
          C 644.333542227745,526.000019788742 642.666453838348,526.000019788742 640.999962329865,526.000019788742
          C 639.889962792397,513.901355206966 634.162293553352,512.667128920555 627.00002104044,507.000030219555
          C 622.333805024624,501.000567555428 617.666196286678,494.999376177788 612.999980270863,488.999988675117
          C 598.727065443993,479.999629676342 573.235674381256,480.595584869385 550.999970257282,481.000028669834
          C 547.384165167809,467.766651570797 549.182169437408,458.680608510971 549.99999576807,443.99999755621
          C 588.421916484833,447.20751273632 577.93123704195,422.451132655144 604.00001090765,410.999984025955
          C 616.906764805317,405.330480158329 642.771106958389,405.929892778397 651.999980151653,400.999977648258
          C 650.151738405228,387.296014666557 648.814526200294,376.632182300091 662.00002348423,373.000004887581
          C 662.99989849329,375.666432738304 664.000071942806,378.333612203598 665.000046432018,380.999964892864
          C 676.94124597311,380.543734848499 684.478757619858,381.706632912159 690.999980032444,386.000005662441
          C 693.237487614155,401.221478462219 688.818678736687,409.974180459976 686.000008106232,419.999967217445
          C 690.999482631683,426.999302744865 696.000548839569,434.000667631626 701.000023365021,441.000003159046
          C 703.358498811722,447.977917671204 698.70790117979,448.975310206413 701.999997854233,455.000027120113
          C 724.06678467989,457.904190659523 741.631398260593,435.530954897404 761.999959409237,452.9999807477
          C 756.479208886623,468.076467096806 744.380134344101,466.855168581009 734.999951839447,477.000011086464
          C 735.333309829235,478.333225011826 735.666667819023,479.666814744473 736.000025808811,481.000028669834
          C 747.665466368198,486.999416172504 759.334488213062,493.000607550144 771.000028252602,498.999995052814
          C 770.173248708248,508.671470701694 774.691438257694,521.002534508705 778.00004863739,526.999967813492
          C 789.981239199638,526.901957273483 800.337421476841,529.099302470684 808.999954164028,527.999990999699
          C 810.9998036623,525.666904211044 813.000250041485,523.333065807819 815.000000059605,520.999979019165
          C 813.666767060757,516.667071342468 812.333235621452,512.332885921001 811.000002622604,507.999978244305
          C 814.332985639572,507.999978244305 817.666963458061,507.999978244305 821.000045955181,507.999978244305
          C 825.332903981209,511.333013057709 829.667055249214,514.667024970055 834.000012755394,517.999984622002
          C 843.938776314259,515.407515645027 848.521130561829,508.189009308815 861.000020325184,510.000024616718
          C 861.33327883482,510.666631579399 861.66673630476,511.333388864994 861.999994814396,511.999995827675
          C 862.355337917805,521.437493860722 861.1618745327,526.733144640923 857.999997377396,532.000008583069
          C 853.33378136158,534.333095371723 848.666172623634,536.666933774948 843.999956607819,539.000020563602
          C 844.999931097031,541.999714314938 846.000104546547,545.000310003757 846.999979555607,548.000003755093
          C 844.333613038063,548.999876618385 841.66635119915,550.000125288963 838.999984681606,550.999998152256
          C 838.999984681606,552.333212077618 838.999984681606,553.666801810265 838.999984681606,555.000015735626
          C 858.853338122368,568.575602948666 878.747776865959,584.600175917149 895.000048279762,601.999978065491
          C 893.666815280914,605.666278779507 892.333184361458,609.333706915379 890.99995136261,613.000007629395
          C 892.999800860882,615.666435480118 895.000247240067,618.333614945412 896.999997258186,620.999967634678
          C 895.666764259338,625.666216373444 894.333232820034,630.333818018436 892.999999821186,634.999991595745
          C 904.499905407429,642.888787806034 916.858026266098,645.818130433559 925.9999538064,656.000027537346
          C 926.999928295612,655.666686475277 928.000101745129,655.333270251751 928.999976754189,655.000004351139
          C 931.281454563141,651.101454734802 932.60304838419,648.296654641628 932.999974191189,642.000003576279
          C 940.991811692715,642.999575793743 943.032348573208,642.539888322353 947.999989449978,639.000009179115
          C 948.999864459038,635.00036740303 950.000137388706,630.99959820509 951.000012397766,627.000031590462
          C 947.650515675545,619.531312763691 944.335041165352,619.419998645782 935.999997138977,617.000025212765
          C 937.999846637249,610.000689685345 940.000193536282,602.999324798584 942.000043034554,595.999989271164
          Z" />
        <path fill="#eeeeee" id="hebei" fillRule="evenodd" d="
          M 1151.99995821714,523.999973416328
          C 1160.03417426348,523.931501328945 1163.55945241451,522.146266400814 1168.0000474453,519.000007808208
          C 1168.8292145133,512.169409751892 1170.24949264526,506.775673270226 1169.00002193451,500.000018239021
          C 1165.61222535372,495.858471632004 1161.59372460842,495.080475389957 1159.99995309114,488.999988675117
          C 1163.20739215612,484.251813948154 1169.75865757465,476.120772361755 1168.0000474453,469.99999910593
          C 1166.18443518877,463.606991052628 1159.95389378071,461.771322786808 1154.00000667572,459.999992728233
          C 1153.3333901763,451.667518436909 1152.66657471657,443.332488656044 1151.99995821714,435.000014364719
          C 1153.25330865383,433.059646189213 1174.88974434137,418.948909401894 1176.0000423193,419.000019192696
          C 1175.52333343029,425.79025554657 1175.40753853321,426.474375128746 1177.99999129772,430.999996781349
          C 1209.00019526482,430.257476747036 1227.34542959929,397.327513694763 1254.00004208088,396.000012040138
          C 1256.65536630154,404.003429472446 1262.51554310322,407.87416934967 1271.00000631809,410.000036001205
          C 1269.9611350894,421.731837511063 1267.48368138075,431.836919605732 1278.00002670288,438.000008761883
          C 1281.99962621927,438.666615724564 1286.00041949749,439.333373010159 1290.00001901388,439.999979972839
          C 1289.00014400482,443.999621748924 1287.99987107515,448.000390946865 1286.99999606609,452.00003272295
          C 1298.95084518194,459.606522500515 1308.82106691599,459.392312347889 1315.99995261431,471.999970316887
          C 1312.0652141571,485.22244900465 1296.46911382675,486.59587430954 1289.00004452467,496.000000655651
          C 1284.82844382524,501.252283275127 1288.35392093658,506.492690384388 1282.00002413988,510.000024616718
          C 1280.66679114103,510.33329051733 1279.33316022158,510.666706740856 1278.00002670288,510.999972641468
          C 1275.66691869497,505.333926200867 1273.333114326,499.666075885296 1271.00000631809,494.000029444695
          C 1258.69301825762,492.964229404926 1261.10153222084,491.519551038742 1263.9999859333,481.000028669834
          C 1259.33376991749,477.66699385643 1254.66616117954,474.332981944084 1250.00004464388,471.000022292137
          C 1240.3034157753,452.740899205208 1249.92344492674,458.911354184151 1227.00003451109,452.9999807477
          C 1226.42493975163,448.560869932175 1226.23373889923,448.331852972507 1224.0000115633,445.999968767166
          C 1222.66677856445,445.999968767166 1221.33324712515,445.999968767166 1220.0000141263,445.999968767166
          C 1214.15446090698,454.595057189465 1205.32102131844,460.848715901375 1200.0000269413,468.999975919724
          C 1200.66664344072,471.666403770447 1201.3333594203,474.333583235741 1201.99997591972,477.000011086464
          C 1196.72583669424,479.574892282486 1193.83464503288,481.991708934307 1190.99995809793,487.000017464161
          C 1193.61718142033,503.738473951817 1216.41226243973,505.418933808804 1232.0000064373,503.000012636185
          C 1232.0000064373,510.332614064217 1232.0000064373,517.667395174503 1232.0000064373,524.999996602535
          C 1241.19983142614,535.860376179218 1251.22593855858,536.315478801727 1255.00001657009,553.999992549419
          C 1241.66798502207,561.332593977451 1228.33197426796,568.667375087738 1215.00004220009,575.99997651577
          C 1212.33357620239,583.332577943802 1209.66641384363,590.667434215546 1207.00004732609,598.000035643578
          C 1200.86321622133,606.114316225052 1192.97483807802,603.046075224876 1192.0000320673,617.999973237514
          C 1181.75258159637,619.312517762184 1157.00062650442,625.645471811295 1150.99998372793,617.000025212765
          C 1142.03473293781,599.338435709476 1161.93414568901,593.033592045307 1158.99997860193,572.999982118607
          C 1157.33348709345,565.334114789963 1155.6664981842,557.66591745615 1154.00000667572,549.999974966049
          C 1150.56157469749,544.933867275715 1147.4119336009,548.48968064785 1146.00001180172,539.999968588352
          C 1149.01555365324,534.603375971317 1151.51837480068,532.442484080791 1151.99995821714,523.999973416328
          Z" />
        <path fill="#eeeeee" id="beijing" fillRule="evenodd" d="
          M 1219.00003963709,456.99999833107
          C 1222.97377431393,457.639622330666 1237.41202515364,460.292220473289 1240.99997580051,462.999987125397
          C 1244.55440163612,466.292810559273 1246.01417338848,477.202871859074 1243.9999987483,480.000005483627
          C 1240.38867026567,487.132376790047 1237.23694008589,486.796254754066 1237.99995285273,497.000023841858
          C 1223.43536221981,496.704564154148 1212.1074578166,498.710172474384 1197.99997848272,491.999983072281
          C 1197.99997848272,490.333503246307 1197.99997848272,488.666497290134 1197.99997848272,487.000017464161
          C 1201.99957799911,484.000323712826 1206.00037127733,480.999728024006 1209.99997079372,478.000034272671
          C 1209.3333542943,475.000265359879 1208.66663831472,471.999669671059 1208.0000218153,468.999975919724
          C 1213.23029029369,466.684702396393 1216.85594391823,462.402378380299 1219.00003963709,456.99999833107
          Z" />
        <path fill="#eeeeee" id="shanxi" fillRule="evenodd" d="
          M 1099.99999153614,487.999965488911
          C 1116.85292410851,488.73414260149 1132.90006744862,484.193413496017 1137.00004243851,471.999970316887
          C 1146.45254701376,474.092465281487 1146.71567201614,477.35311961174 1158.99997860193,477.000011086464
          C 1156.9763533473,484.319684743881 1153.560105443,485.463115990162 1153.00003218651,494.999977469444
          C 1156.33301520348,497.333064258099 1159.66699302197,499.666902661324 1162.99997603893,501.999989449978
          C 1163.34447580576,508.311898291111 1163.13904929161,513.185292005539 1159.99995309114,516.000013411045
          C 1154.4217030406,518.154291093349 1149.98916590214,518.303937554359 1146.00001180172,520.999979019165
          C 1145.16786032915,531.384962379932 1140.55964124203,539.016105115414 1135.99996846914,546.000032544136
          C 1139.19646471739,550.186901509762 1145.33956307173,551.454048514366 1150.00000923872,553.999992549419
          C 1150.16763329506,563.855839252472 1154.20712435246,574.252848446369 1153.00003218651,581.000017285347
          C 1149.00043267012,589.332491576672 1144.9996393919,597.667521357536 1141.00003987551,605.999995648861
          C 1141.99991488457,608.999689400196 1143.00008833408,612.000285089016 1143.99996334314,614.999978840351
          C 1145.2330198288,622.654797434807 1138.76293021441,647.513246774673 1134.00001949072,650.999986767769
          C 1122.68186414242,659.285409986973 1109.95666152239,652.081334650517 1094.00004512072,657.999998748302
          C 1080.02984839678,663.181855082512 1076.20404070616,680.308896303177 1051.00004780293,673.999993920326
          C 1053.05968487263,645.016533493996 1075.41993814707,642.834370911121 1048.99999934435,616.000002026558
          C 1050.2654863596,595.815317571163 1068.17151582241,595.230711758137 1071.00003498793,578.000022888184
          C 1073.78060472012,561.060659825802 1047.93217939138,568.380107998848 1063.00004011393,542.999962985516
          C 1068.26084899902,534.138652682304 1071.50240975618,524.285436630249 1076.99998140335,514.999990224838
          C 1080.30958658457,509.410157501698 1099.86111724377,501.438157558441 1100.99996602535,498.999995052814
          C 1100.66670751572,495.333694338799 1100.33334952593,491.666266202927 1099.99999153614,487.999965488911
          Z" />
        <path fill="#eeeeee" id="tianjin" fillRule="evenodd" d="
          M 1250.00004464388,482.999999880791
          C 1251.33317816257,482.999999880791 1252.66680908203,482.999999880791 1254.00004208088,482.999999880791
          C 1252.98007208109,495.269581615925 1263.210511446,502.198791503906 1271.9999808073,507.000030219555
          C 1271.9999808073,509.333117008209 1271.9999808073,511.666880249977 1271.9999808073,513.999967038631
          C 1253.53228640556,513.407995402813 1254.11295205355,518.367899954319 1246.00004720688,526.999967813492
          C 1243.33358120918,525.333487987518 1240.66641885042,523.666482031345 1237.99995285273,522.000002205372
          C 1237.81780469418,507.818839132786 1244.66979861259,490.514642357826 1250.00004464388,482.999999880791
          Z" />
        <path fill="#eeeeee" id="qinghai" fillRule="evenodd" d="
          M 794.000038385391,565.999970138073
          C 794.054056107998,572.172153830528 794.997326910496,574.999352037907 798.000035822392,578.000022888184
          C 800.805276632309,582.364047408104 806.040618598461,584.854747772217 811.999977111816,585.999982893467
          C 812.362880706787,625.505972146988 845.040817439556,619.964844048023 799.999984800816,649.000015556812
          C 800.188002288342,664.172407925129 792.252271592617,665.829868376255 783.000020563602,670.999999523163
          C 783.666637063026,672.999820411205 784.333353042603,675.000167429447 784.999969542027,676.99998831749
          C 788.332952558994,677.999936342239 791.667029857635,679.00010985136 795.000012874603,679.999982714653
          C 794.393880307674,685.737357378006 793.41061848402,687.839923977852 787.999992489815,688.999965906143
          C 780.308883488178,685.649719119072 769.005650162697,676.159232258797 760.999984920025,678.000011503696
          C 754.988299846649,679.986679136753 752.250108659267,683.248310565948 751.0000410676,689.99998909235
          C 758.279700160027,695.658744871616 758.363959848881,706.096641898155 764.999982357025,711.999973058701
          C 768.637275159359,710.377988815308 772.481685638428,709.672824025154 776.999974668026,711.000025033951
          C 776.999974668026,712.333163797855 776.999974668026,713.666828691959 776.999974668026,714.999967455864
          C 772.291380107403,720.426173686981 767.552842020988,722.314004004002 760.999984920025,725.999997019768
          C 760.000109910965,731.332777559757 758.999936461449,736.667211651802 757.999961972237,741.999992191792
          C 757.666703462601,741.999992191792 757.333345472813,741.999992191792 756.999987483025,741.999992191792
          C 748.000913441181,741.333385229111 738.999053955078,740.666627943516 729.999979913235,740.000020980835
          C 726.681123077869,725.708220243454 710.079178929329,727.054662585258 698.999974906445,721.000031411648
          C 696.413490951061,707.590325534344 694.879407525063,694.688034653664 687.999957084656,685.99997150898
          C 674.356453180313,687.050879001617 654.794377624989,689.237325787544 644.999959766865,695.000029861927
          C 644.999959766865,695.333295762539 644.999959766865,695.666711986065 644.999959766865,695.999977886677
          C 646.666451275349,700.666226625443 648.333539664745,705.333828270435 650.000031173229,710.000001847744
          C 646.667048156261,713.999568462372 643.33297085762,718.00041282177 639.999987840652,721.999979436398
          C 641.3332208395,725.999621212482 642.666752278805,730.000390410423 643.999985277653,734.000032186508
          C 637.873400628567,740.381690859795 637.415195047855,755.125963568687 627.999995529652,757.999987363815
          C 620.667412996292,754.667027711868 613.332641899586,751.333015799522 605.999959886074,747.999980986118
          C 600.730595707893,750.964874982834 599.649942815304,753.319006979465 600.00001347065,762.000004947186
          C 594.66718095541,761.000132083893 589.332756757736,759.999883413315 584.000023722649,759.000010550022
          C 584.12616455555,744.274152398109 570.867449879646,718.807421386242 561.999988079071,715.999990642071
          C 549.66793102026,715.666724741459 537.332093715668,715.333308517933 525.000036656857,714.999967455864
          C 504.757616341114,709.138877034187 497.648764669895,694.911113858223 471.000021517277,694.00000667572
          C 471.30791258812,686.359994888306 470.535648167133,682.084134757519 467.999998569489,678.000011503696
          C 455.878342032433,678.255034327507 444.052042067051,679.524060368538 433.99997061491,676.99998831749
          C 430.320597708225,669.444833815098 426.523838222027,662.646329700947 421.000003814697,656.999975562096
          C 421.864287376404,643.297515809536 427.819666683674,635.558516383171 432.000021636486,624.999985218048
          C 428.883407950401,618.925210773945 424.473651766777,618.436360657215 422.999952793121,611.000036418438
          C 426.333035290241,606.333787679672 429.66701310873,601.666186034679 432.999996125698,597.000012457371
          C 434.869427144527,592.53316706419 433.924564659595,583.421493947506 436.999993562698,579.99999409914
          C 439.99971807003,577.000300347805 443.000337898731,573.999704658985 445.999962925911,571.00001090765
          C 447.666454434395,571.666617870331 449.333542823792,572.333375155926 451.000034332275,572.999982118607
          C 455.458535790443,585.537213802338 466.10818451643,586.077399194241 475.000018954277,578.999970912933
          C 476.015313386917,566.675897121429 469.021460771561,560.911088526249 470.000047028065,552.999969363213
          C 474.666163563728,552.666703462601 479.333772301674,552.333287239075 483.99998831749,552.000021338463
          C 484.333346307278,551.000073313713 484.666704297066,549.999899804592 484.999962806702,549.000026941299
          C 482.181391656399,528.298306822777 466.256509423256,528.903807520866 465.999950110912,504.000035822392
          C 475.138694286346,502.86915653944 471.130738437176,500.143275976181 475.999993443489,497.000023841858
          C 489.742778539658,488.128566741943 593.710878252983,486.617671132088 608.999982833862,494.000029444695
          C 620.420801699162,499.514475226402 630.624878823757,525.891185998917 639.999987840652,533.999979794025
          C 657.41766923666,531.440882503986 656.984234213829,513.626339435577 663.999972462654,500.999966263771
          C 664.999946951866,500.999966263771 666.000120401382,500.999966263771 666.999995410442,500.999966263771
          C 671.301716148853,508.219224214554 675.629500687122,516.722615659237 682.999985158443,520.999979019165
          C 693.035443425179,518.472449541092 700.285556912422,513.74802583456 712.00004118681,513.000019013882
          C 724.468684494495,519.713365197182 727.944521009922,534.958062887192 739.000048756599,542.000014960766
          C 744.988952875137,545.814759552479 748.382917225361,540.451237976551 753.999964535236,542.000014960766
          C 765.516085386276,545.175285875797 774.857868552208,563.552562773228 794.000038385391,565.999970138073
          Z" />
        <path fill="#eeeeee" id="shaanxi" fillRule="evenodd" d="
          M 1065.99996358156,730.000014603138
          C 1046.26857280731,731.105789959431 1039.16539150476,740.650092422962 1025.00001472235,730.000014603138
          C 1023.00016522408,730.999887466431 1020.99981832504,732.000136137009 1018.99996882677,733.000009000301
          C 1017.9223998189,736.581677913666 1016.66457277536,738.403290987015 1017.99999433756,741.999992191792
          C 1019.82605201006,745.725444972515 1021.09452342987,746.41625392437 1025.00001472235,747.999980986118
          C 1023.17952793837,752.681337177753 1019.86942535639,753.47774797678 1017.00001984835,756.999964177608
          C 1020.48381477594,765.157462596893 1023.9868093729,768.809632956982 1022.99996626377,779.999971330166
          C 1021.66683274508,779.999971330166 1020.33320182562,779.999971330166 1018.99996882677,779.999971330166
          C 1018.99996882677,779.999971330166 990.999986767769,766.000022530556 990.999986767769,766.000022530556
          C 986.000512242317,766.666629493237 980.999545514584,767.333386778831 975.99997150898,767.999993741512
          C 967.424284994602,765.224506616592 966.796366274357,753.56087654829 960.999956250191,747.999980986118
          C 953.538347959518,740.841528654099 935.68394869566,757.091886639595 925.9999538064,750.000027358532
          C 919.673115611076,745.366624176502 927.221470594406,741.826444387436 912.999987006187,737.999974608421
          C 913.686499536037,733.222637236118 912.653696596622,726.763336777687 915.000035464764,724.000025808811
          C 920.63439232111,720.919758975506 928.642644047737,723.127401292324 934.000048160553,721.999979436398
          C 935.626747608185,713.324167609215 939.592921793461,693.366921722889 939.000020086765,688.999965906143
          C 935.667037069798,687.666827142239 932.332959771156,686.333237409592 928.999976754189,685.00002348423
          C 928.999976754189,683.333468496799 928.999976754189,681.666462540627 928.999976754189,679.999982714653
          C 931.333084762096,677.000288963318 933.666889131069,673.999693274498 935.999997138977,670.999999523163
          C 947.499803245068,671.266747534275 951.889762878418,677.921167135239 959.000007271767,675.999965131283
          C 963.666223287582,672.333664417267 968.333832025528,668.666311442852 973.000048041344,665.000010728836
          C 984.593763411045,661.469752252102 991.55110681057,666.839888036251 998.000007152557,659.000021934509
          C 995.288675606251,653.781862616539 992.773419439793,651.446896791458 991.999961256981,643.999974787235
          C 996.465724766254,639.667442917824 1004.11226665974,630.29713922739 999.999956130981,620.999967634678
          C 995.896499335766,613.465106725693 992.741187870502,617.994862258434 985.000040352345,614.000030815601
          C 980.281796216965,611.565175414085 960.857301712036,591.933754444122 955.999984323978,587.000006079674
          C 955.845193207264,579.081070125103 957.272633910179,576.412462592125 959.999981760979,572.000034093857
          C 963.780128061771,570.582564175129 967.05382090807,568.6233304739 970.999999582767,569.999987721443
          C 989.421336233616,583.34362667799 996.148582041264,584.834754824638 1013.00002241135,566.99999332428
          C 1011.6667894125,562.667085647583 1010.33315849304,558.332900226116 1009.00002497435,553.999992549419
          C 1021.45971506834,543.832601606846 1027.72596985102,518.782490551472 1041.99997895956,511.999995827675
          C 1046.45559549332,509.882847905159 1066.60430550575,507.170721888542 1072.00000947714,506.000007033348
          C 1072.00000947714,507.666486859322 1072.00000947714,509.333492815495 1072.00000947714,510.999972641468
          C 1065.60343569517,530.087976276875 1051.91725480556,540.861544370651 1051.00004780293,562.99997574091
          C 1080.18304783106,584.42159229517 1046.02056878805,588.754499971867 1041.99997895956,614.999978840351
          C 1046.66619497538,621.665973305702 1051.33380371332,628.333997130394 1056.00001972914,634.999991595745
          C 1060.84917974472,654.688309788704 1039.62041372061,662.991020143032 1045.00000190735,682.000029087067
          C 1052.25817328691,695.08999812603 1057.46367120743,708.663631141186 1068.00001204014,718.999985039234
          C 1067.33339554071,722.66628575325 1066.66658008099,726.333713889122 1065.99996358156,730.000014603138
          Z" />
        <path fill="#eeeeee" id="ningxia" fillRule="evenodd" d="
          M 938.000045597553,517.000036597252
          C 939.666537106037,517.333302497864 941.333526015282,517.66671872139 943.000017523766,517.999984622002
          C 942.456259012222,532.304186999798 935.582180500031,534.895227909088 939.000020086765,549.000026941299
          C 944.178359925747,552.283079385757 952.642330229282,554.654197871685 958.000032782555,558.00001013279
          C 958.666649281979,560.333096921444 959.333365261555,562.666935324669 959.999981760979,565.000022113323
          C 950.999614477158,571.713668942451 951.987750828266,578.941420137882 947.000014960766,590.000000476837
          C 935.024096846581,589.406074643135 932.848764359951,604.563284397125 928.000002264977,613.000007629395
          C 931.485786795616,621.611556410789 934.888604879379,623.032558917999 943.999992012978,626.000008404255
          C 942.318578481674,642.461494922638 930.575543403625,629.676230430603 924.999979317188,640.999980390072
          C 916.667521774769,638.333627700806 908.332477748394,635.666373074055 900.000020205975,633.000020384789
          C 902.958957850933,622.118745923042 904.498512685299,620.593795120716 897.999971747398,611.999984443188
          C 899.679992556572,606.96619617939 902.929810166359,605.6199041605 901.999969184399,601.000030040741
          C 897.498989701271,591.947208344936 888.869384944439,589.440573751926 884.000030457973,578.999970912933
          C 884.333288967609,578.000098049641 884.66674643755,576.99992454052 885.000004947186,575.99997651577
          C 894.437985420227,574.955307424068 907.376970887184,573.881776332855 913.999961495399,569.999987721443
          C 921.190088450909,544.921315312386 918.035373866558,536.049256920815 938.000045597553,517.000036597252
          Z" />
        <path fill="#eeeeee" id="tibet" fillRule="evenodd" d="
          M 464.000001132488,685.00002348423
          C 463.800841867924,692.04746234417 464.741426706314,695.11607915163 467.000024080276,698.99997228384
          C 503.320227622986,707.53387928009 516.384359121323,722.80262863636 561.999988079071,723.000002622604
          C 565.756856024265,731.172007203102 574.563237011433,737.421532034874 577.00000333786,744.999986588955
          C 579.923227608204,754.091516435146 577.089137554169,759.087723970413 581.00000077486,764.999999344349
          C 589.999074816704,767.666427195072 599.000934302807,770.333606660366 608.00000834465,773.000034511089
          C 608.333266854286,772.66669344902 608.666724324226,772.333277225494 608.999982833862,772.000011324883
          C 608.333366334438,766.667230784893 607.66655087471,761.332796692848 607.000033855438,756.000016152859
          C 615.999107897282,759.332975804806 625.000867903233,762.666987717152 634.000041425228,766.000022530556
          C 640.842783689499,754.198020219803 644.366171717644,730.692777752876 662.999997973442,741.999992191792
          C 665.21353083849,760.381478130817 674.70702123642,767.725128293037 678.999987721443,782.000017702579
          C 673.872382760048,783.285203456879 672.463246405125,784.117766916752 670.000018358231,788.000006496906
          C 673.999617874622,792.666180074215 678.00041115284,797.333781719208 682.000010669231,802.000030457973
          C 685.416457533836,812.712943255901 684.531979501247,826.826385855675 686.000008106232,834.999968826771
          C 683.0002835989,837.666396677494 679.999663770199,840.333576142788 677.000038743019,843.000003993511
          C 678.934529781342,849.421347916126 682.019707739353,850.033312499523 682.999985158443,857.999975979328
          C 657.352012336254,868.089800298214 672.905435681343,874.967449426651 662.00002348423,887.999995112419
          C 660.666790485382,888.333336174488 659.333159565926,888.666677236557 658.00002604723,889.000018298626
          C 654.964389204979,884.044848918915 638.06688952446,880.577124774456 630.000043988228,876.999965548515
          C 625.215048670769,881.755656421185 621.189285874367,892.160407245159 616.000003218651,896.00003027916
          C 614.333511710167,896.333296179771 612.666522800922,896.666712403297 611.000031292439,896.999978303909
          C 604.580477595329,888.814294338226 574.923454642296,875.477419912815 561.000013589859,880.000035107136
          C 552.46909314394,882.771012544632 550.683623373508,891.914328634739 542.999975383282,895.000007092953
          C 532.001051843166,897.333093881607 520.998944938183,899.666932284832 510.000021398067,902.000019073486
          C 503.16444170475,904.864647686481 503.348281025887,911.133263528347 495.000006139278,912.999973475933
          C 477.949903905392,906.72541987896 454.946213006973,906.431764066219 432.999996125698,905.00001347065
          C 433.540471792221,897.013206720352 435.498241186142,892.595065951347 432.999996125698,886.999971926212
          C 429.333655118942,885.666833162308 425.666319310665,884.333168268204 421.999978303909,883.000029504299
          C 422.312942862511,876.994027793407 422.528814792633,874.320008635521 418.999955356121,871.999999940395
          C 404.240881025791,864.818698525429 377.467688202858,867.720156252384 367.99996316433,854.999981582165
          C 366.00021314621,855.666588544846 363.999766767025,856.333420991898 362.000016748905,857.000027954578
          C 359.089625418186,862.906816542149 353.841152071953,873.042113542557 347.000001490116,874.999994337559
          C 346.333384990692,874.666728436947 345.666569530964,874.333312213421 344.99995303154,873.999971151352
          C 345.294215321541,862.773329794407 347.279739677906,855.455535173416 343.999978542328,848.999992787838
          C 324.662330210209,844.884978175163 292.926271557808,850.664743900299 273.999973654747,841.999980807304
          C 272.000223636627,839.66689401865 269.999777257442,837.333130776882 268.000027239323,834.999968826771
          C 260.667444705963,834.999968826771 253.332574129105,834.999968826771 245.999991595745,834.999968826771
          C 240.419950902462,832.504683613777 240.07007920742,824.960126876831 236.00004774332,821.000020027161
          C 225.331298828125,810.619320869446 208.121232509613,805.780576586723 195.999973893166,796.999989688396
          C 196.646495401859,759.946368455887 172.519474625587,773.026491343975 159.999996960163,756.999964177608
          C 150.164792239666,744.409743666649 154.132856547832,725.462592601776 136.000012338161,721.000031411648
          C 127.467500209808,718.900095462799 122.924241662025,727.586880862713 115.000050663948,725.999997019768
          C 109.18812173605,710.207672953606 86.9076515436172,709.593829333782 81.0000227093697,695.999977886677
          C 77.8309829831123,688.707813322544 80.2642675042152,683.996693193913 77.0000252723694,679.000034689903
          C 70.466467320919,678.97402882576 64.2593037486076,678.919160962105 58.9999870657921,675.999965131283
          C 52.8834499120712,659.243695378304 56.5036321282387,644.233501434326 58.9999870657921,627.000031590462
          C 59.999862074852,627.000031590462 61.0001350045204,627.000031590462 62.0000100135803,627.000031590462
          C 63.6715750098228,632.000373005867 68.0074174404144,634.811486661434 73.0000278353691,633.000020384789
          C 80.5733523368835,632.180985987186 84.5171434879303,628.845621168613 86.999969124794,623.000014007092
          C 95.9715866446495,606.132054328918 77.574622631073,587.472395837307 85.0000201463699,574.000005304813
          C 97.9986937046051,571.000311553478 111.001346468925,567.999715864658 124.000020027161,565.000022113323
          C 130.478366494179,559.796068310738 140.544066727161,547.580527663231 148.999979138374,545.000009357929
          C 155.666044652462,547.666437208652 162.33400028944,550.333616673946 168.999966323376,552.999969363213
          C 186.856554150581,556.699040472507 201.188759148121,543.311732709408 215.999961078167,552.999969363213
          C 224.833002746105,579.709720551968 271.951975762844,565.281125962734 296.000009298325,562.00002771616
          C 299.995530128479,573.984822690487 314.855676293373,571.410918593407 326.000039815903,568.000016510487
          C 327.686526834965,564.32604932785 328.499976038933,562.014684200287 331.999986231327,559.999981343746
          C 350.528562486172,552.485789835453 378.341024458408,560.716946482658 401.000016629696,561.000004529953
          C 407.309644758701,574.634067356586 412.703956007957,578.104121506214 428.000024199486,582.999988496304
          C 427.499639034271,598.689642012119 413.058503270149,605.090241372585 417.000006377697,617.000025212765
          C 419.666372895241,619.666377902031 422.333634734154,622.333632528782 425.000001251698,624.999985218048
          C 421.865282177925,635.940336585045 415.707162320614,643.137196421623 413.999983429909,656.999975562096
          C 420.41217559576,665.194152772427 425.195181310177,679.355623543262 435.000044584274,684.000000298023
          C 443.819556951523,686.695064663887 453.23256790638,685.260232448578 464.000001132488,685.00002348423
          Z" />
        <path onClick={this.onRegionClick} fill="#73eb7b" id="shandong" fillRule="evenodd" d="
          M 1403.99999570847,582.999988496304
          C 1398.66716319323,582.333381533623 1393.3328384757,581.666624248028 1388.00000596046,581.000017285347
          C 1381.03390830755,589.878464400768 1364.22524440289,588.171021580696 1357.99997544289,597.000012457371
          C 1352.86421310902,603.529438555241 1355.11654323339,608.998862624168 1357.00000095367,617.000025212765
          C 1321.386703372,628.377741098404 1330.60612595081,647.016880512238 1310.00000619888,666.999981939793
          C 1305.00053167343,666.999981939793 1299.99946546555,666.999981939793 1294.99999094009,666.999981939793
          C 1294.00011593103,669.999675691128 1292.99994248152,673.000271379948 1291.99996799231,675.999965131283
          C 1288.00036847591,675.00009226799 1283.9995751977,673.999918758869 1279.9999756813,672.999970734119
          C 1274.49066537619,673.720693945885 1269.40902024508,678.704650163651 1260.99996298552,675.999965131283
          C 1256.33384644985,673.333612442017 1251.66623771191,670.666432976723 1247.00002169609,668.000005125999
          C 1242.81240469217,667.344221413136 1219.51315826178,671.937638700008 1208.99999630451,672.00002270937
          C 1205.15170609951,664.661182880402 1201.03850024939,662.17965221405 1193.00000655651,659.000021934509
          C 1193.00000655651,658.00007390976 1193.00000655651,656.999900400639 1193.00000655651,656.000027537346
          C 1199.18677669764,652.539744377136 1205.24054187536,649.819951891899 1209.99997079372,644.999997973442
          C 1208.78163737059,632.271480381489 1193.32530665398,627.181997478008 1198.99995297194,611.999984443188
          C 1205.17319381237,599.835102617741 1214.45220500231,598.621996700764 1220.99998861551,578.999970912933
          C 1235.11900764704,575.873254299164 1241.51916271448,564.034498035908 1252.99996811152,561.000004529953
          C 1259.6660336256,561.999877393246 1266.33398926258,563.000126063824 1272.99995529652,563.999998927116
          C 1281.02462124825,562.034902632236 1286.35297715664,553.296406149864 1298.99998837709,559.000033318996
          C 1298.63618946075,569.252056062222 1301.23530739546,574.009325325489 1307.99995774031,576.999999701977
          C 1318.06526005268,582.964437127113 1337.77327299118,576.173449158669 1345.00000864267,572.000034093857
          C 1346.66650015116,568.000392317772 1348.3334890604,563.999623119831 1349.99998056889,559.999981343746
          C 1354.6661965847,558.666767418385 1359.33380532265,557.333177685738 1364.00002133846,555.999963760376
          C 1365.99977135658,558.666391611099 1368.00021773577,561.333571076393 1369.99996775389,563.999998927116
          C 1378.66578328609,562.666785001755 1387.33418530226,561.333195269108 1396.00000083447,559.999981343746
          C 1398.45616579056,565.022570550442 1401.48643070459,565.190180599689 1407.00001865625,566.99999332428
          C 1406.00014364719,572.332773864269 1404.99987071753,577.667207956314 1403.99999570847,582.999988496304
          Z" />
        <path fill="#eeeeee" id="henan" fillRule="evenodd" d="
          M 1193.99998104572,624.999985218048
          C 1194.88724452257,635.714175760746 1200.13004750013,635.3331823349 1202.99995040894,643.000026762486
          C 1202.6666918993,643.666633725166 1202.33333390951,644.333391010761 1201.99997591972,644.999997973442
          C 1193.90706658363,647.028004407883 1189.18912088871,651.184733629227 1185.00001168251,656.999975562096
          C 1185.33327019215,658.333189487457 1185.66672766209,659.666779220104 1185.99998617172,660.999993145466
          C 1191.13734018803,668.541543424129 1209.68979167938,679.362463235855 1221.99996310472,678.000011503696
          C 1220.936221838,690.244263827801 1228.05681216717,689.73805141449 1229.99995797873,702.99998986721
          C 1229.00008296967,702.99998986721 1227.99990952015,702.99998986721 1227.00003451109,702.99998986721
          C 1219.31190991402,698.648592472076 1212.53890323639,692.183579742908 1202.99995040894,698.00002425909
          C 1202.99995040894,698.333290159702 1202.99995040894,698.666706383228 1202.99995040894,698.99997228384
          C 1204.57203525305,706.095138669014 1206.87301117182,709.286494135857 1207.00004732609,718.999985039234
          C 1203.32604634762,720.686532974243 1201.01472449303,721.499930262566 1198.99995297194,724.999973833561
          C 1198.6666944623,726.999794721603 1198.33333647251,729.000216901302 1197.99997848272,731.000037789345
          C 1193.48666346073,731.21530020237 1192.60924851894,731.707682907581 1189.99998360872,733.000009000301
          C 1189.99998360872,733.333274900913 1189.99998360872,733.666691124439 1189.99998360872,734.000032186508
          C 1193.02079790831,745.451706945896 1200.89962595701,752.647289037704 1208.99999630451,759.000010550022
          C 1212.66633731127,759.000010550022 1216.33367311954,759.000010550022 1220.0000141263,759.000010550022
          C 1219.66665613651,763.9995251894 1219.33329814672,769.000467896461 1219.00003963709,773.999982535839
          C 1206.19167160988,776.56952726841 1207.79827606678,786.602604687214 1197.99997848272,789.999977707863
          C 1193.6671204567,789.00010484457 1189.33286970854,787.999931335449 1185.00001168251,786.999983310699
          C 1184.70276498795,780.479576587677 1183.67473709583,780.454848468304 1181.00001424551,776.999976933002
          C 1173.66743171215,778.999797821045 1166.33256113529,781.000220000744 1158.99997860193,782.999965727329
          C 1154.98655134439,780.408624172211 1157.87376379967,758.855399906635 1146.00001180172,751.999998569489
          C 1139.000687778,754.999692320824 1131.99927467108,758.000288009644 1124.99995064735,760.999981760979
          C 1110.64695429802,753.174246013165 1092.61518192291,750.996668279171 1073.99995845556,744.999986588955
          C 1073.26808297634,731.651762783527 1070.59385752678,729.772500872612 1076.00000691414,720.000008225441
          C 1066.75213301182,704.894058585167 1059.87636333704,706.135575532913 1056.00001972914,681.00000590086
          C 1082.79649090767,681.497649908066 1081.03758233786,670.958435237408 1095.00001960993,665.000010728836
          C 1101.66598564386,665.000010728836 1108.33404076099,665.000010728836 1115.00000679493,665.000010728836
          C 1139.81761878729,659.787789165974 1141.95126909018,651.058988511562 1147.99996078014,626.000008404255
          C 1148.33331876993,626.000008404255 1148.66667675972,626.000008404255 1149.00003474951,626.000008404255
          C 1164.30227071047,627.697980880737 1177.74273562431,625.522883474827 1193.99998104572,624.999985218048
          Z" />
        <path onClick={this.onRegionClick} fill="#FFFFCF" id="anhui" fillRule="evenodd" d="
        M 1260.99996298552,850.999963998795
          C 1260.66670447588,848.66687721014 1260.33334648609,846.333113968372 1259.9999884963,844.000027179718
          C 1259.00011348724,842.333472192287 1257.99994003773,840.666466236115 1256.99996554852,838.999986410141
          C 1255.66683202982,838.999986410141 1254.33320111036,838.999986410141 1252.99996811152,838.999986410141
          C 1249.06721925735,842.924015760422 1247.10029768944,844.846495509148 1239.00002682209,844.999975204468
          C 1235.33368581533,838.667321801186 1231.66635000706,832.33271420002 1228.0000090003,825.999985635281
          C 1228.33326750994,819.000725269318 1228.66672497988,811.9992852211 1228.99998348951,805.000024855137
          C 1223.70286434889,798.23519307375 1211.0054166913,795.639492154121 1208.0000218153,791.00000089407
          C 1212.28124964237,782.216257214546 1217.75176268816,781.517105340958 1225.99996054173,776.999976933002
          C 1227.78324174881,769.530130684376 1228.5673443079,756.522313117981 1223.00003707409,753.000021755695
          C 1216.99849897623,750.998697638512 1208.91394597292,752.828277826309 1202.99995040894,750.000027358532
          C 1200.06677812338,742.958000123501 1196.96618074179,740.167631030083 1197.99997848272,731.000037789345
          C 1206.91568815708,730.296000421047 1209.6134904027,724.111866056919 1213.99996823072,718.999985039234
          C 1213.3333517313,713.667204499245 1212.66663575172,708.3327704072 1212.0000192523,702.99998986721
          C 1224.33227527142,705.723690748215 1225.33125495911,713.498091220856 1236.0000038743,705.999984264374
          C 1235.82183492184,693.289430260658 1230.23801398277,691.442863583565 1228.0000090003,682.000029087067
          C 1231.66635000706,680.000208199024 1235.33368581533,677.999786019325 1239.00002682209,675.999965131283
          C 1250.6340316534,678.918108701706 1252.80150520802,691.874140024185 1258.00003951788,701.000018656254
          C 1269.42951315641,703.438782453537 1280.25046670437,705.394633889198 1287.99997055531,711.000025033951
          C 1289.46083658934,724.95420050621 1292.03846681118,731.913775622845 1299.99996286631,738.999997794628
          C 1304.99953687191,737.666783869267 1310.00050359964,736.33319413662 1314.9999781251,734.999980211258
          C 1317.67221397161,737.612291812897 1318.37892097235,739.032242059708 1318.9999755621,743.999963402748
          C 1309.5991011858,746.998078763485 1303.48196715117,753.955023229122 1302.99998581409,766.000022530556
          C 1304.33321881294,767.666502356529 1305.66675025225,769.333508312702 1306.99998325109,770.999988138676
          C 1312.11465579271,771.663513481617 1317.90589284897,773.185307502747 1321.00002402067,776.000028908253
          C 1319.91449660063,779.844011306763 1318.42945688963,783.100155949593 1319.99995005131,786.999983310699
          C 1323.5909845829,794.299137890339 1333.60744214058,794.909148275852 1341.99998569489,796.999989688396
          C 1341.99998569489,798.333203613758 1341.99998569489,799.666793346405 1341.99998569489,801.000007271767
          C 1335.54849886894,806.448987424374 1335.52999556065,816.642835199833 1329.99999338388,822.999991238117
          C 1325.72881305218,827.910138905048 1319.72568327188,827.942308008671 1315.99995261431,834.000020802021
          C 1317.36850106716,837.756064295769 1317.57432550192,838.88874745369 1317.00002658367,843.000003993511
          C 1309.92549556494,848.136537969112 1298.42230713367,856.683598220348 1285.00004708767,851.999987185001
          C 1282.66693907976,850.000166296959 1280.33313471079,847.999819278717 1278.00002670288,845.999998390675
          C 1272.60074144602,844.810643494129 1269.37062090635,850.786806106567 1260.99996298552,850.999963998795
          Z" />
        <path onClick={this.onRegionClick} fill="#8d95f0" id="jiangsu" fillRule="evenodd" d="
          M 1398.00004929304,786.999983310699
          C 1383.50499528646,788.048560798168 1378.19534164667,795.125387787819 1369.99996775389,802.000030457973
          C 1351.47805666924,799.882882535458 1344.78662371635,790.725811898708 1326.9999704361,786.999983310699
          C 1324.42164385319,771.981747090816 1329.6036644578,769.068714499474 1310.00000619888,764.999999344349
          C 1310.00000619888,763.666785418987 1310.00000619888,762.33319568634 1310.00000619888,760.999981760979
          C 1314.131516397,752.437663733959 1321.49533569813,751.670340418816 1326.9999704361,744.999986588955
          C 1325.08587282896,737.100667774677 1321.92777591944,732.708608031273 1317.00002658367,727.999968230724
          C 1311.3339355588,729.333182156086 1305.66605389118,730.666771888733 1299.99996286631,731.999985814095
          C 1285.87159270048,701.590186417103 1297.20049190521,704.96764165163 1260.99996298552,695.000029861927
          C 1259.66682946682,690.66712218523 1258.33319854736,686.332936763763 1256.99996554852,682.000029087067
          C 1258.666457057,682.000029087067 1260.3335454464,682.000029087067 1262.00003695488,682.000029087067
          C 1274.3137896657,684.986344099045 1280.56323230267,679.49662643671 1290.00001901388,684.000000298023
          C 1294.28890681267,680.529194533825 1297.83069866896,673.874399125576 1302.99998581409,672.00002270937
          C 1306.66632682085,675.332982361317 1310.33366262913,678.666994273663 1314.00000363588,682.000029087067
          C 1319.99935317039,682.333294987679 1326.0005928278,682.666711211205 1332.00004184246,682.999977111816
          C 1338.48664516211,686.367962002754 1340.26405704021,696.882523715496 1345.99998313189,701.000018656254
          C 1358.09527742863,709.682369530201 1374.55884468555,711.842133998871 1384.99998301268,721.999979436398
          C 1379.87456661463,754.239584505558 1391.78244030476,748.544826388359 1407.00001865625,766.000022530556
          C 1403.34670954943,774.404877305031 1398.11534678936,773.392903447151 1398.00004929304,786.999983310699
          Z" />
        <path onClick={this.onRegionClick} fill="#e88ebb" id="sichuan" fillRule="evenodd" d="
          M 682.999985158443,692.999983489513
          C 686.827285051346,694.321021258831 685.079816699028,692.998405098915 686.999982595444,695.999977886677
          C 689.333090603352,705.665666103363 691.666894972324,715.334285616875 694.000002980232,724.999973833561
          C 701.229822516441,735.120839834213 716.346925914288,729.398647785187 728.000030934811,747.000032961369
          C 739.936355948448,747.820720911026 751.286145985126,748.644715964794 760.999984920025,750.999975383282
          C 761.999859929085,750.000102519989 763.000132858753,748.999929010868 764.000007867813,747.999980986118
          C 764.666624367237,742.000593483448 765.333439826965,735.999402105808 765.999956846237,730.000014603138
          C 771.332789361477,728.000193715096 776.667213559151,725.999771535397 782.00004607439,724.000025808811
          C 783.333179593086,725.666505634785 784.666810512543,727.333511590958 786.000043511391,728.999991416931
          C 794.644371330738,734.008224785328 805.671248793602,728.458753764629 811.999977111816,724.999973833561
          C 811.999977111816,723.666835069656 811.999977111816,722.333170175552 811.999977111816,721.000031411648
          C 808.950015127659,714.314344644547 806.351693034172,708.458966493607 806.000030696392,698.99997228384
          C 806.666647195816,698.99997228384 807.333363175392,698.99997228384 807.999979674816,698.99997228384
          C 810.666445672512,698.333365321159 813.333608031273,697.666608035564 815.999974548817,697.000001072884
          C 821.94570428133,719.037866413593 848.121817231178,711.554791748524 861.999994814396,724.999973833561
          C 859.000270307064,731.3327023983 855.999650478363,737.667309999466 853.000025451183,743.999963402748
          C 857.464694678783,750.905722916126 875.060445547104,755.045089840889 881.999981999397,759.000010550022
          C 893.391653180122,756.953815042973 898.423259794712,748.88733714819 905.999966621399,743.000015377998
          C 917.756232559681,743.920592904091 919.204663574696,750.552088260651 924.999979317188,756.999964177608
          C 935.33228635788,756.000091314316 945.667677283287,754.999917805195 955.999984323978,753.999969780445
          C 958.501114308834,763.589293956757 970.533935070038,773.984424114227 982.000017404556,775.000005722046
          C 984.798493564129,803.369246721268 972.548806071281,809.284303069115 955.999984323978,822.999991238117
          C 950.620595097542,827.458493709564 951.604155361652,834.324643135071 943.000017523766,835.999992012978
          C 930.896565854549,826.892903745174 925.847550213337,833.931849360466 912.999987006187,837.000015199184
          C 907.000637471676,837.000015199184 900.999397814274,837.000015199184 895.000048279762,837.000015199184
          C 894.00007379055,837.666622161865 892.999900341034,838.33337944746 892.000025331974,838.999986410141
          C 892.987465322018,845.830058336258 894.646495819092,850.415959477425 892.000025331974,856.000004768372
          C 889.000300824642,857.666484594345 885.999680995941,859.333490550518 882.999956488609,860.999970376492
          C 882.999956488609,862.333184301853 882.999956488609,863.6667740345 882.999956488609,864.999987959862
          C 890.10234194994,873.165979623795 906.116656839848,881.965056240559 909.999964058399,892.000012695789
          C 901.089526832104,892.226699650288 893.078489661217,894.008176505566 888.000027894974,898.000001490116
          C 888.404812633991,902.030233979225 888.32721811533,901.911704361439 889.999976873398,903.999990284443
          C 894.119847893715,909.743753671646 906.493686616421,911.943428874016 912.999987006187,915.000019848347
          C 912.666728496552,916.66649967432 912.333271026611,918.333505630493 912.000012516975,919.999985456467
          C 911.333396017551,919.999985456467 910.666580557823,919.999985456467 909.999964058399,919.999985456467
          C 899.814589202404,924.314854383469 886.855110824108,917.944319605827 877.999984562397,918.00001424551
          C 876.336775898933,912.531041145325 874.499576449394,910.570229053497 869.999989688396,908.000007867813
          C 867.666881680489,908.999880731106 865.333077311516,910.000129401684 862.999969303608,911.000002264977
          C 860.000344276428,907.333701550961 856.999724447727,903.666273415089 853.999999940395,899.999972701073
          C 854.884179532528,893.90881305933 855.935087859631,893.531427383423 853.999999940395,889.000018298626
          C 848.000650405884,889.999891161919 841.999410748482,891.00006467104 835.999961733818,892.000012695789
          C 832.568095445633,896.999752819538 831.997676253319,901.519211232662 830.000015318394,909.00003105402
          C 826.667032301426,910.333169817924 823.332955002785,911.666834712029 819.999971985817,912.999973475933
          C 819.999971985817,916.999615252018 819.999971985817,921.000384449959 819.999971985817,925.000026226044
          C 815.808375775814,931.289837598801 805.286559045315,933.006224632263 799.000010311604,937.000003814697
          C 798.666652321815,946.665692031384 798.333294332027,956.334311544895 798.000035822392,965.999999761581
          C 790.334095299244,967.666479587555 782.665866732597,969.333485543728 775.000025689602,970.999965369701
          C 770.202893793583,970.11644244194 769.051908433437,969.603766143322 767.000030815601,965.999999761581
          C 758.829228520393,942.242966413498 754.27661883831,950.182271122932 743.0000461936,934.000009417534
          C 732.502801060677,918.936150193214 732.483004510403,914.107402384281 718.999962091446,900.99999588728
          C 719.556553542614,888.420748949051 719.030601978302,883.773891866207 713.999990165234,876.000017523766
          C 711.666882157326,875.666676461697 709.333077788353,875.333335399628 706.999969780445,874.999994337559
          C 701.333878755569,878.333029150963 695.666096568108,881.666965901852 690.000005543232,885.000000715256
          C 689.491860926151,869.377391219139 687.440978109837,854.425146758556 683.999959647655,843.000003993511
          C 686.666425645351,840.333576142788 689.333588004112,837.666396677494 691.999954521656,834.999968826771
          C 690.056012868881,817.6264731884 684.724473595619,801.639029979706 681.000036180019,788.000006496906
          C 683.333144187927,786.333526670933 685.6669485569,784.66652071476 687.999957084656,782.999965727329
          C 680.99774813652,767.876879274845 673.130957186222,759.342370986938 669.000043869019,738.999997794628
          C 648.179146468639,725.887856125832 642.466100811958,732.102055072784 656.999952077866,708.999978661537
          C 656.000077068806,705.333677947521 654.999903619289,701.666324973106 654.000028610229,698.00002425909
          C 654.333287119865,697.666683197021 654.666744589806,697.333266973495 655.000003099442,697.000001072884
          C 664.332435131073,695.666787147522 673.667553126812,694.333197414875 682.999985158443,692.999983489513
          Z" />
        <path fill="#eeeeee" id="hubei" fillRule="evenodd" d="
          M 1067.00003755093,737.000026583672
          C 1067.01704865694,744.16772377491 1068.48089909554,746.847079396248 1071.00003498793,750.999975383282
          C 1082.33221703768,752.999796271324 1093.6677814126,755.000218451023 1104.99996346235,756.999964177608
          C 1112.6659039855,760.666264891624 1120.33413255215,764.333693027496 1127.99997359514,767.999993741512
          C 1134.33268111944,765.000299990177 1140.66727876663,761.999704301357 1146.99998629093,759.000010550022
          C 1148.94104301929,767.306779623032 1151.01719379425,781.07425403595 1154.99998116493,788.000006496906
          C 1165.14068943262,790.574060916901 1168.33489763737,786.758113741875 1177.99999129772,785.000012099743
          C 1182.81483066082,799.137882173061 1209.78061705828,798.162436783314 1221.99996310472,805.000024855137
          C 1221.66670459509,811.9992852211 1221.3333466053,819.000725269318 1220.99998861551,825.999985635281
          C 1223.50659000874,835.23890709877 1231.49783062935,838.700994133949 1232.99998092651,850.000015974045
          C 1227.66724789143,849.666674911976 1222.33282369375,849.333333849907 1216.99999117851,848.999992787838
          C 1214.00026667118,852.666293501854 1210.99974632263,856.333721637726 1208.0000218153,860.000022351742
          C 1204.33368080854,859.666681289673 1200.66634500027,859.333265066147 1197.00000399351,858.999999165535
          C 1191.43846660852,874.937460005283 1185.89413928986,860.983209371567 1173.99999386072,867.000034332275
          C 1171.00026935339,870.332993984222 1167.99974900484,873.667005896568 1165.00002449751,876.999965548515
          C 1163.00017499924,876.666699647903 1160.9998281002,876.333283424377 1158.99997860193,876.000017523766
          C 1158.6667200923,875.333410561085 1158.33326262236,874.666578114033 1158.00000411272,873.999971151352
          C 1159.62879264355,866.077652931213 1161.68982243538,863.157404839993 1158.00000411272,857.000027954578
          C 1157.3333876133,856.666686892509 1156.66657215357,856.333270668983 1155.99995565414,856.000004768372
          C 1145.35806638002,857.225437164307 1145.99931544065,863.818600177765 1138.00001692772,867.000034332275
          C 1137.00014191866,866.333427369595 1135.99986898899,865.666594922543 1134.99999397993,864.999987959862
          C 1133.28406083584,858.766923487186 1131.42676639557,857.437993764877 1126.00002461672,854.999981582165
          C 1113.54242360592,859.534096479416 1069.51529371738,834.235802292824 1053.99997127056,848.999992787838
          C 1054.99994575977,851.999686539173 1056.00011920929,855.000282227993 1056.99999421835,857.999975979328
          C 1054.00026971102,858.999924004078 1050.99974936247,860.000097513199 1048.00002485514,860.999970376492
          C 1041.08923816681,838.455366492271 1019.95846009254,867.894155025482 1012.00004792213,871.999999940395
          C 1009.96985697746,860.167482078075 1005.43455684185,867.500459313393 998.000007152557,866.000011146069
          C 994.875634014606,855.689663112164 986.285423398018,846.183692991734 989.000037789345,834.999968826771
          C 1008.41786712408,835.715130090714 1036.36383146048,829.065220177174 1041.99997895956,818.000025629997
          C 1042.33333694935,812.333904027939 1042.66669493914,806.666128873825 1042.99995344877,801.000007271767
          C 1039.92601674795,794.780171215534 1032.80463057756,792.4414473176 1028.00003767014,788.000006496906
          C 1030.14303910732,773.98998606205 1028.01655137539,769.485409617424 1025.99998921156,756.999964177608
          C 1030.33294671774,756.666698276997 1034.66709798574,756.333282053471 1038.99995601177,756.000016152859
          C 1039.33331400156,755.000068128109 1039.66667199135,753.999894618988 1040.00002998114,753.000021755695
          C 1038.66679698229,750.00032800436 1037.33316606283,746.99973231554 1036.00003254414,743.999963402748
          C 1047.60459125042,742.469901621342 1052.64395731688,737.314502120018 1067.00003755093,737.000026583672
          Z" />
        <path fill="#eeeeee" id="chongqing" fillRule="evenodd" d="
          M 1010.99997395277,909.00003105402
          C 1001.91594386101,909.345698595047 996.939946651459,908.179643750191 991.999961256981,905.00001347065
          C 988.697220206261,887.428542554379 973.598023235798,888.81497079134 963.999979197979,876.000017523766
          C 962.666845679283,876.000017523766 961.333214759827,876.000017523766 959.999981760979,876.000017523766
          C 956.000382244587,878.999711275101 951.99958896637,882.000306963921 947.999989449978,885.000000715256
          C 944.981363713741,888.880060613155 946.954055130482,894.932662427425 943.000017523766,898.000001490116
          C 940.317933142185,900.170438885689 937.771440207958,901.539129018784 934.000048160553,899.999972701073
          C 919.261466741562,893.349386334419 900.571633160114,877.320829808712 890.99995136261,864.999987959862
          C 896.554823577404,856.267805039883 900.848187983036,857.518341362476 900.000020205975,843.000003993511
          C 900.999895215034,843.000003993511 902.000068664551,843.000003993511 903.000043153763,843.000003993511
          C 914.333021044731,844.572531998158 919.505988955498,838.878300011158 928.000002264977,837.000015199184
          C 938.361158549786,834.708868503571 938.907901465893,846.846166074276 948.99996393919,843.000003993511
          C 950.666455447674,841.333524167538 952.33354383707,839.666518211365 954.000035345554,837.999963223934
          C 964.221322536469,810.90801602602 990.634098768234,820.707491636276 989.000037789345,775.000005722046
          C 997.238685548306,776.414469182491 1004.65035480261,780.19478982687 1013.99999690056,782.999965727329
          C 1015.8020798564,793.151948571205 1029.39425200224,797.645852088928 1037.00000703335,802.000030457973
          C 1036.66674852371,806.999469935894 1036.33329105377,812.000487804413 1036.00003254414,817.00000244379
          C 1025.90677630901,819.240790963173 1018.41243904829,824.921118080616 1009.99999946356,828.000032007694
          C 1000.66259342432,831.417473137379 990.68761909008,826.842320084572 982.999991893768,831.999974429607
          C 982.666733384132,832.666581392288 982.333275914192,833.33341383934 982.000017404556,834.000020802021
          C 981.682178318501,849.894038319588 989.569462180138,862.694936394691 995.999958693981,873.000023126602
          C 999.666299700737,873.333289027214 1003.33373498917,873.66670525074 1006.99997651577,873.999971151352
          C 1011.09000349045,884.451773047447 1011.38396733999,893.967589318752 1010.99997395277,909.00003105402
          Z" />
        <path onClick={this.onRegionClick} fill="#d9ffdf" id="zhejiang" fillRule="evenodd" d="
          M 1414.00003904104,902.999967098236
          C 1412.33354753256,903.333308160305 1410.66645914316,903.666724383831 1408.99996763468,903.999990284443
          C 1399.09840965271,907.814058423042 1392.99599868059,917.19856762886 1392.99997788668,931.00001502037
          C 1380.59679251909,931.334032535553 1368.37615323067,933.295145273209 1360.00002390146,928.999968647957
          C 1359.11644119024,924.202938973904 1358.60372048616,923.05191642046 1354.9999524951,921.000008642673
          C 1344.71838033199,917.826691925526 1337.11978787184,926.616974651814 1326.9999704361,919.999985456467
          C 1327.92235040665,890.186216413975 1311.98055654764,889.363799750805 1302.00001132488,871.999999940395
          C 1304.65493762493,858.168412804604 1315.64719599485,856.367469131947 1321.99999850988,847.000021576881
          C 1322.66661500931,842.333772838116 1323.33343046904,837.666171193123 1324.00004696846,832.999997615814
          C 1326.29147279263,829.515061497688 1334.26052933931,827.811000943184 1337.00001376867,824.000014424324
          C 1339.66638028622,818.000626921654 1342.33364212513,811.999435544014 1345.00000864267,805.999972879887
          C 1355.57922542095,803.561885535717 1362.5051330924,809.310384094715 1372.99999070168,808.0000192523
          C 1376.99312400818,804.941323757172 1396.45880329609,792.260834336281 1398.00004929304,789.000029683113
          C 1401.82734918594,790.320992290974 1400.07988083363,788.998451292515 1402.00004673004,792.000024080276
          C 1402.00004673004,793.66650390625 1402.00004673004,795.333509862423 1402.00004673004,796.999989688396
          C 1391.12209105492,800.684329152107 1379.53016632795,814.938924968243 1379.00003659725,828.999980032444
          C 1380.66652810574,830.333193957806 1382.33351701498,831.666783690453 1384.00000852346,832.999997615814
          C 1391.66594904661,831.000176727772 1399.33407813311,828.99982970953 1407.00001865625,827.000008821487
          C 1413.33272618055,832.666130423546 1419.66732382774,838.33390557766 1426.00003135204,844.000027179718
          C 1425.66667336226,846.666379868984 1425.33331537247,849.333634495735 1424.99995738268,851.999987185001
          C 1420.66709935665,857.999374687672 1416.33294808865,864.000566065311 1411.99999058247,870.000028729439
          C 1416.99349629879,883.167714715004 1413.75770539045,887.008840978146 1414.00003904104,902.999967098236
          Z" />
        <path fill="#eeeeee" id="jiangxi" fillRule="evenodd" d="
          M 1176.0000423193,1010.99999088049
          C 1175.56511509418,995.886299610138 1178.33195656538,988.237493932247 1180.0000397563,974.999982953072
          C 1177.11322522163,972.153768897057 1176.99195891619,971.217407464981 1172.0000448823,970.000017344952
          C 1174.16682207584,955.058671295643 1168.38573199511,943.478470444679 1161.00002706051,935.000032603741
          C 1163.16998761892,922.404776275158 1170.67735677958,918.98229932785 1177.00001680851,911.000002264977
          C 1177.32680910826,895.583635807037 1173.6392788291,889.686843693256 1170.99997091293,880.000035107136
          C 1172.99982041121,877.666873157024 1175.00016731024,875.333109915257 1177.00001680851,873.000023126602
          C 1200.20565241575,874.338498353958 1208.20335924625,867.004168212414 1220.0000141263,854.999981582165
          C 1234.00672006607,856.114175021648 1241.13875061274,851.675364851952 1252.99996811152,848.999992787838
          C 1257.4707056284,858.391041040421 1283.705511868,859.01112306118 1298.00001388788,860.000022351742
          C 1294.23349636793,890.761878013611 1314.31157547235,877.573146820068 1317.00002658367,902.000019073486
          C 1305.16646456718,921.936445236206 1282.01793056726,916.171035349369 1275.9999782443,928.000020623207
          C 1275.33336174488,933.332801163197 1274.6666457653,938.667235255241 1274.00002926588,944.000015795231
          C 1263.81495285034,950.344845354557 1250.07097399235,951.887609422207 1250.00004464388,968.999994158745
          C 1252.76479703188,975.291910052299 1256.05430722237,977.953001439571 1256.99996554852,986.000012516975
          C 1250.39219540358,999.165518820286 1242.65214216709,1005.32890862226 1240.0000013113,1023.99999165535
          C 1221.64462000132,1027.00584864616 1209.58682972193,1044.08140426874 1188.0000346303,1036.99999243021
          C 1190.17984372377,1029.61267346144 1197.15628731251,1029.06406998634 1201.00000143051,1022.99996846914
          C 1200.66674292088,1021.00022274256 1200.33328545094,1018.99980056286 1200.0000269413,1016.99997967482
          C 1195.20886385441,1010.59426933527 1187.79311591387,1010.45409321785 1176.0000423193,1010.99999088049
          Z" />
        <path onClick={this.onRegionClick} fill="#fa7e78" id="hunan" fillRule="evenodd" d="
          M 1064.00001460314,851.999987185001
          C 1073.48863047361,851.833203911781 1077.02027535439,855.02260518074 1083.00002729893,856.000004768372
          C 1086.66626882553,855.333397805691 1090.33370411396,854.666565358639 1094.00004512072,854.000033557415
          C 1106.65183138847,853.599498152733 1109.3172031045,862.466671049595 1115.00000679493,869.000005543232
          C 1119.90109544992,866.043153822422 1120.33005386591,863.677897930145 1127.99997359514,863.000016748905
          C 1129.96082687378,869.996496140957 1133.12688219547,873.731569588184 1142.00001436472,873.999971151352
          C 1142.33327287436,873.999971151352 1142.6667303443,873.999971151352 1142.99998885393,873.999971151352
          C 1145.66058552265,869.745757520199 1147.04475235939,866.335456728935 1153.00003218651,864.999987959862
          C 1151.6897791028,873.052861630917 1151.46206903458,875.061326086521 1154.99998116493,880.999983131886
          C 1164.01089334488,883.398685872555 1166.08565139771,886.849573850632 1170.99997091293,893.000035881996
          C 1170.6667124033,898.666082322598 1170.33325493336,904.333932638168 1169.99999642372,909.99997907877
          C 1161.72294932604,915.996660768986 1155.31921297312,924.006166279316 1154.00000667572,937.000003814697
          C 1166.53052663803,949.632014095783 1160.13206273317,961.726394474506 1168.0000474453,976.000006139278
          C 1169.66653895378,976.33327203989 1171.33352786303,976.666688263416 1173.00001937151,977.000029325485
          C 1170.91282629967,990.135170400143 1168.04192858934,997.717082381248 1169.99999642372,1012.0000140667
          C 1167.66688841581,1012.0000140667 1165.33308404684,1012.0000140667 1162.99997603893,1012.0000140667
          C 1157.58228695393,1009.62370944023 1155.50534033775,1007.54677289724 1147.99996078014,1006.99997329712
          C 1138.49562704563,1011.3316783905 1135.6731761694,1016.32209849358 1137.00004243851,1029.00003242493
          C 1136.0000679493,1029.00003242493 1134.99989449978,1029.00003242493 1134.00001949072,1029.00003242493
          C 1127.97530251741,1025.10591733456 1119.6129014492,1020.44305086136 1111.99998384714,1023.99999165535
          C 1111.66672533751,1027.33302646875 1111.33326786757,1030.66696321964 1111.00000935793,1033.99999803305
          C 1109.66677635908,1034.33326393366 1108.33324491978,1034.66668015718 1107.00001192093,1035.00002121925
          C 1100.63377958536,1030.75287276506 1098.72644662857,1032.16966623068 1090.00004768372,1033.99999803305
          C 1090.5622100234,1027.96145141125 1091.03464126587,1025.24752151966 1088.99997371435,1020.99999725819
          C 1084.66711568832,1021.3332631588 1080.33286494017,1021.66667938232 1076.00000691414,1022.00002044439
          C 1080.58683776855,1006.29225301743 1086.43935459852,993.636566877365 1072.99998396635,980.999971747398
          C 1055.89775413275,981.585855305195 1033.56276881695,995.165877044201 1024.00004023314,987.000035703182
          C 1017.88738280535,981.0649112463 1020.84522616863,968.228687286377 1024.00004023314,960.000010967255
          C 1019.95388400555,954.345238745213 1018.40199363232,952.617201685905 1007.99995100498,952.999998986721
          C 1012.33290851116,947.000611484051 1016.66705977917,940.999420106411 1021.00001728535,935.000032603741
          C 1023.76258111,925.942701220512 1019.0736836195,915.481504142284 1017.99999433756,909.99997907877
          C 1012.76544821262,883.275646567345 1022.50137174129,867.420111715794 1037.99998152256,860.000022351742
          C 1042.38546454906,861.573377132416 1044.86550474167,865.096871078014 1048.00002485514,867.999982357025
          C 1056.55492001772,866.841669142246 1059.4660077095,863.875121593475 1064.00001460314,858.999999165535
          C 1064.00001460314,856.666912376881 1064.00001460314,854.333073973656 1064.00001460314,851.999987185001
          Z" />
        <path fill="#eeeeee" id="yunnan" fillRule="evenodd" d="
          M 826.999992370605,915.999967873096
          C 829.666358888149,915.666701972485 832.333620727062,915.333285748959 834.999987244606,915.000019848347
          C 836.66647875309,909.000632345676 838.333467662334,902.99936580658 839.999959170818,896.999978303909
          C 842.333067178726,896.666712403297 844.666871547699,896.333296179771 846.999979555607,896.00003027916
          C 847.237737119198,901.659913480282 856.990472793579,920.031778752804 861.000020325184,921.000008642673
          C 864.049783349037,921.736440598965 867.829233288765,917.531307399273 872.000038146973,918.00001424551
          C 872.000038146973,918.666621208191 872.000038146973,919.333378493786 872.000038146973,919.999985456467
          C 874.482067942619,923.881548583508 875.523923575878,929.862596690655 873.999987125397,935.000032603741
          C 859.647488176823,936.843592822552 847.280215144157,938.074512004852 835.999961733818,937.000003814697
          C 830.228322267532,943.515750527382 823.738137662411,944.991921544075 822.999994933605,957.000016570091
          C 829.439643621445,973.318245351315 835.97389793396,969.753863573074 851.999951481819,967.000022947788
          C 851.043449819088,979.371673941612 844.357587754726,982.434879124165 838.999984681606,990.000030100346
          C 839.333342671394,991.33316886425 839.666700661182,992.666833758354 839.999959170818,993.999972522259
          C 842.888862788677,1000.45363587141 848.579425930977,1004.05883032084 856.000048398972,1006.00002527237
          C 856.000048398972,1007.33316403627 856.000048398972,1008.66682893038 856.000048398972,1009.99996769428
          C 850.88617169857,1013.73368823528 847.018482863903,1018.10207211971 843.999956607819,1023.99999165535
          C 848.703875601292,1048.41904711723 865.973828971386,1061.39341783524 895.000048279762,1059.99999958277
          C 892.95533323288,1073.90915334225 846.75635266304,1095.35422027111 834.999987244606,1104.99999070168
          C 816.820785284042,1096.03818953037 817.91675812006,1103.75877439976 798.000035822392,1106.00001388788
          C 792.759819328785,1100.85273182392 791.053436279297,1095.70131587982 783.999995052814,1091.99998992682
          C 783.000120043755,1091.99998992682 781.999946594238,1091.99998992682 780.999972105026,1091.99998992682
          C 776.367081940174,1096.6919438839 772.262132942677,1107.46137809753 764.999982357025,1109.00000828505
          C 760.667124330997,1107.33352845907 756.33287358284,1105.6665225029 752.000015556812,1103.99996751547
          C 748.14983522892,1106.4957036972 746.161724388599,1106.6170142889 744.000020682812,1110.999979496
          C 744.333279192448,1123.66543662548 744.666736662388,1136.33457666636 744.999995172024,1149.00003379583
          C 741.333654165268,1148.66669273376 737.666318356991,1148.33327651024 733.999977350235,1148.00001060963
          C 731.920742690563,1137.89763432741 735.875078737736,1127.1720944047 726.999956965446,1122.00000905991
          C 725.666823446751,1121.66666799784 724.333192527294,1121.33332693577 722.999959528446,1120.9999858737
          C 717.064874172211,1125.91644710302 709.752983510494,1134.89613670111 701.000023365021,1136.00003302097
          C 697.333682358265,1134.66681909561 693.666346549988,1133.33322936296 690.000005543232,1132.0000154376
          C 689.27837651968,1105.76919305325 675.67934024334,1118.92462772131 667.999969899654,1106.99996191263
          C 671.950028300285,1099.36280626059 674.133120238781,1089.45171588659 678.000013232231,1083.00000673532
          C 677.666655242443,1082.00013387203 677.333297252655,1080.99988520145 677.000038743019,1080.00001233816
          C 663.912330448627,1073.61993205547 660.900071442127,1065.96105474234 652.999954640865,1055.00003397465
          C 655.333062648773,1049.33391237259 657.666867017746,1043.66613721848 659.999975025654,1038.00001561642
          C 659.000100016594,1037.00006759167 657.999926567078,1035.99989408255 656.999952077866,1035.00002121925
          C 638.2532158494,1034.90772294998 629.686184108257,1037.50447613001 612.999980270863,1038.00001561642
          C 612.666721761227,1036.00019472837 612.333264291286,1033.99977254868 612.000005781651,1032.00002682209
          C 608.981280565262,1027.14835476875 605.118267297745,1028.93697196245 603.000036418438,1022.99996846914
          C 609.427449047565,1021.80031645298 615.706437289715,1019.31472706795 619.000026166439,1015.00000846386
          C 624.666117191315,1003.33449935913 630.333899378777,991.665532827377 635.999990403652,980.000023722649
          C 641.909409880638,971.39290946722 653.148080587387,967.858441948891 658.00002604723,957.999964594841
          C 658.666642546654,957.333432793617 659.33335852623,956.666600346565 659.999975025654,955.999993383884
          C 666.890567243099,933.075448334217 647.533818721771,912.934658169746 648.999957203865,902.000019073486
          C 650.8182554245,888.439614474773 662.849086582661,900.128874599934 669.000043869019,892.000012695789
          C 669.999918878078,885.000677168369 671.000092327595,877.999312281609 671.999967336655,870.999976754189
          C 673.999816834927,870.000103890896 676.000163733959,868.999930381775 678.000013232231,867.999982357025
          C 681.022717654705,869.259312570095 679.196063101292,867.981567800045 681.000036180019,870.000028729439
          C 685.239482343197,880.702043116093 684.385843157768,890.182383179665 690.000005543232,898.000001490116
          C 691.999755561352,897.333394527435 694.000201940536,896.66663724184 695.999951958656,896.00003027916
          C 696.644384384155,886.742994904518 700.852593779564,886.293303906918 705.999995291233,882.000006318092
          C 707.999844789505,882.333272218704 710.000191688538,882.66668844223 712.00004118681,883.000029504299
          C 712.754100739956,891.443968236446 711.171769440174,901.633456647396 714.999964654446,908.000007867813
          C 718.333047151566,909.333221793175 721.667024970055,910.666811525822 725.000007987022,912.000025451183
          C 729.999482512474,921.332372605801 735.00054872036,930.667575895786 740.000023245811,939.999998211861
          C 746.332730770111,945.33277875185 752.667328417301,950.667212843895 759.000035941601,955.999993383884
          C 765.246693134308,966.736356556416 758.61753475666,974.425674259663 772.000002741814,977.999977350235
          C 781.998951792717,976.00023162365 792.000984728336,973.999809443951 802.000033259392,971.999988555908
          C 805.598727762699,962.81909173727 802.907590687275,946.642091333866 806.000030696392,938.999975025654
          C 817.966597676277,937.532372415066 822.479614257812,932.725797235966 827.999966859818,925.000026226044
          C 827.666708350182,922.000332474709 827.333250880241,918.999736785889 826.999992370605,915.999967873096
          Z" />
        <path fill="#eeeeee" id="guizhou" fillRule="evenodd" d="
          M 958.000032782555,885.000000715256
          C 958.000032782555,885.000000715256 982.999991893768,896.999978303909 982.999991893768,896.999978303909
          C 985.666358411312,902.332834005356 988.333620250225,907.667192935944 990.999986767769,912.999973475933
          C 996.673538804054,916.722945928574 1008.41925984621,915.964341342449 1013.99999690056,919.000037431717
          C 1013.99999690056,924.332817971706 1013.99999690056,929.667176902294 1013.99999690056,935.000032603741
          C 1008.00064736605,940.666079044342 1001.99940770864,946.333929359913 995.999958693981,951.999975800514
          C 996.333316683769,953.666530787945 996.666674673557,955.333536744118 997.000032663345,957.000016570091
          C 1003.16869741678,958.704377770424 1007.98731702566,960.046761393547 1017.00001984835,960.000010967255
          C 1015.50632536411,970.455721259117 1014.7490824461,978.412388265133 1016.00004535913,989.000006914139
          C 1007.18849140406,991.201711475849 1012.12499499321,995.341078400612 1006.99997651577,1000.00003647804
          C 999.525734245777,1006.79455703497 988.683392465115,1004.87388116121 978.000019967556,1009.00001966953
          C 974.3336789608,1012.33297932148 970.666343152523,1015.66699123383 967.000002145767,1019.00002604723
          C 953.480947911739,1017.52175050974 949.679910778999,1010.16840451956 939.000020086765,1007.99999648333
          C 932.528438270092,1015.03315466642 902.805062055588,1039.1115784049 889.999976873398,1033.99999803305
          C 885.551920831203,1031.5695771575 884.071357727051,1026.64695268869 881.000007510185,1022.99996846914
          C 872.000933468342,1026.99961024523 862.999073982239,1031.00037944317 853.999999940395,1035.00002121925
          C 853.000124931335,1031.00037944317 851.999852001667,1026.99961024523 850.999976992607,1022.99996846914
          C 855.332934498787,1018.00052899122 859.667085766792,1012.9995111227 864.000043272972,1007.99999648333
          C 860.152747869492,1000.11443221569 852.352409660816,998.123630702496 846.000005066395,993.000024497509
          C 846.000005066395,992.000076472759 846.000005066395,990.999902963638 846.000005066395,990.000030100346
          C 853.483399510384,985.035390377045 857.269415140152,976.611820399761 861.000020325184,967.999970972538
          C 857.333679318428,964.667011320591 853.666343510151,961.332999408245 850.000002503395,957.999964594841
          C 844.074367761612,961.676261782646 841.357564806938,964.854839801788 831.999964296818,963.000005364418
          C 829.775986015797,953.159792244434 830.88857203722,951.486247241497 835.999961733818,944.999963819981
          C 848.337291240692,945.953988194466 870.232773244381,943.875247776508 880.000033020973,938.000027000904
          C 880.999908030033,933.667119324207 882.000081479549,929.33293390274 882.999956488609,925.000026226044
          C 899.043220102787,926.014405250549 912.945869803429,930.466443836689 920.000007390976,918.00001424551
          C 919.01107519865,912.553589582443 917.544837236404,912.409279584885 915.000035464764,909.00003105402
          C 910.203003048897,907.461776673794 900.289905369282,904.44081223011 897.999971747398,899.999972701073
          C 917.740415215492,898.257279157639 923.62516361475,901.183314681053 935.999997138977,908.000007867813
          C 942.156027913094,904.370912075043 947.725424230099,901.914560496807 952.99996137619,898.000001490116
          C 952.381393790245,890.683409452438 952.7202231884,890.694458186626 955.999984323978,886.000023901463
          C 956.666600823402,885.666682839394 957.333416283131,885.333266615868 958.000032782555,885.000000715256
          Z" />
        <path onClick={this.onRegionClick} fill="#FBD6C6" id="fujian" fillRule="evenodd" d="
          M 1247.9999961853,1033.99999803305
          C 1246.06580358744,1001.08589404821 1258.66287577152,1007.11948001385 1263.00001144409,987.999983727932
          C 1265.74466884136,975.900868177414 1255.71328926086,972.039824128151 1258.00003951788,958.999987781048
          C 1268.59905284643,956.950259685516 1279.56186509132,951.189509809017 1282.00002413988,941.000021398067
          C 1282.00002413988,937.000379621983 1282.00002413988,932.999610424042 1282.00002413988,928.999968647957
          C 1282.66664063931,928.666702747345 1283.33335661888,928.333286523819 1283.99997311831,928.000020623207
          C 1284.66658961773,927.666679561138 1285.33340507746,927.333263337612 1286.00002157688,926.999997437
          C 1289.99962109327,926.999997437 1294.00041437149,926.999997437 1298.00001388788,926.999997437
          C 1304.99933791161,923.333696722984 1312.00065153837,919.666268587112 1318.9999755621,915.999967873096
          C 1319.33333355188,915.999967873096 1319.66669154167,915.999967873096 1319.99995005131,915.999967873096
          C 1322.74590069056,938.193718075752 1340.17890202999,925.530440628529 1353.99997800589,928.000020623207
          C 1355.10341185331,933.421642005444 1356.55910491943,935.008676171303 1360.99999839067,937.000003814697
          C 1367.46760100126,938.399810791016 1382.69771385193,935.759764611721 1385.99995750189,941.000021398067
          C 1365.10912555456,950.061636984348 1365.92655396461,965.632535398006 1359.00004941225,989.000006914139
          C 1351.54918497801,991.206747293472 1343.63185811043,995.731391847134 1340.00003671646,1002.000007689
          C 1342.22451239824,1008.59294521809 1345.68224352598,1008.58858585358 1345.00000864267,1016.00003165007
          C 1337.36908513308,1018.94072365761 1332.66616094112,1025.96576440334 1326.9999704361,1028.00000923872
          C 1320.11335742474,1030.47244536877 1313.88062745333,1028.5586091876 1309.00003170967,1031.00000363588
          C 1309.00003170967,1036.33278417587 1309.00003170967,1041.66721826792 1309.00003170967,1046.99999880791
          C 1309.00003170967,1046.99999880791 1282.99999862909,1058.99997639656 1282.99999862909,1058.99997639656
          C 1282.66674011946,1058.66671049595 1282.33328264952,1058.33329427242 1282.00002413988,1058.00002837181
          C 1282.58636015654,1046.38217163086 1280.58859974146,1039.44379204512 1272.99995529652,1035.999969244
          C 1267.7244233489,1033.01500713825 1256.31663638353,1033.76579493284 1247.9999961853,1033.99999803305
          Z" />
        <path fill="#eeeeee" id="guangxi" fillRule="evenodd" d="
          M 1083.00002729893,1029.00003242493
          C 1082.991571486,1035.32749968767 1083.85665088892,1036.58186924458 1085.99995076656,1039.99998682737
          C 1090.25501531363,1040.42367196083 1096.05132585764,1039.21890896559 1099.00001704693,1042.00003319979
          C 1108.12751996517,1061.82627266645 1080.71964377165,1072.31490367651 1087.00002473593,1096.00000751019
          C 1075.32602888346,1103.5995824337 1025.88379639387,1154.82188993692 1009.99999946356,1149.00003379583
          C 1003.21008116007,1146.5112876296 996.783663332462,1137.52152639627 989.000037789345,1133.99998664856
          C 986.333571791649,1134.99993467331 983.666409432888,1136.00010818243 981.000042915344,1136.99998104572
          C 980.000068426132,1139.99967479706 978.999894976616,1143.00027048588 978.000019967556,1145.99996423721
          C 955.221452653408,1142.30735701323 943.764423012733,1138.19189143181 928.000002264977,1130.9999922514
          C 926.666769266129,1124.00073188543 925.333237826824,1116.99929183722 924.000004827976,1110.00003147125
          C 924.931238532066,1107.1715555191 927.193019270897,1107.41086959839 928.000002264977,1101.99999630451
          C 926.000152766705,1099.33356845379 923.999805867672,1096.66638898849 921.9999563694,1094.00003629923
          C 904.249513864517,1094.74390923977 889.220350921154,1093.99650371075 882.999956488609,1083.00000673532
          C 882.999956488609,1082.00013387203 882.999956488609,1080.99988520145 882.999956488609,1080.00001233816
          C 893.773358523846,1075.32188808918 896.917727172375,1070.80100160837 904.000017642975,1062.99999397993
          C 903.666659653187,1061.00017309189 903.333301663399,1058.99982607365 903.000043153763,1057.0000051856
          C 892.441916167736,1052.23995494843 880.338961899281,1056.06477117538 869.000015199184,1051.99996441603
          C 866.666907191277,1047.00052493811 864.333102822304,1041.99950706959 861.999994814396,1036.99999243021
          C 862.66661131382,1036.99999243021 863.333426773548,1036.99999243021 864.000043272972,1036.99999243021
          C 867.682997465134,1033.24154376984 872.928685367107,1031.40429711342 880.000033020973,1031.00000363588
          C 881.628921031952,1035.8455876112 883.559034943581,1037.99400269985 888.000027894974,1039.99998682737
          C 904.034537255764,1044.64819681644 933.533088326454,1024.510864079 940.999969065189,1016.00003165007
          C 948.577571213245,1019.41085857153 960.44276791811,1024.96393734217 967.000002145767,1029.99998044968
          C 970.666343152523,1025.33380687237 974.3336789608,1020.66620522738 978.000019967556,1016.00003165007
          C 985.332602500916,1014.33347666264 992.667373597622,1012.66647070646 999.999956130981,1010.99999088049
          C 1012.73232132196,1005.63338768482 1055.06918394566,981.504455447197 1073.99995845556,990.000030100346
          C 1077.09876519442,1006.43693882227 1064.79764646292,1018.94290333986 1071.00003498793,1029.99998044968
          C 1074.99963450432,1029.66671454906 1079.00042778254,1029.33329832554 1083.00002729893,1029.00003242493
          Z" />
        <path fill="#eeeeee" id="taiwan" fillRule="evenodd" d="
          M 1432.99995225668,1006.00002527237
          C 1434.99980175495,1006.00002527237 1437.00024813414,1006.00002527237 1438.99999815226,1006.00002527237
          C 1440.99984765053,1008.33311206102 1443.00019454956,1010.66687530279 1445.00004404783,1012.99996209145
          C 1445.20984768867,1033.19201236963 1437.72625428438,1038.38574421406 1434.00002622604,1051.00001639128
          C 1432.6667932272,1060.33236354589 1431.33316230774,1069.66756683588 1430.00002878904,1078.99998915195
          C 1426.03146708012,1088.4502646327 1417.10352134705,1087.59612983465 1415.99998801947,1101.99999630451
          C 1414.00023800135,1101.33338934183 1411.99979162216,1100.66663205624 1410.00004160404,1100.00002509356
          C 1389.15278196335,1056.37007701397 1412.12483817339,1033.86207675934 1432.99995225668,1006.00002527237
          Z" />
        <g onClick={this.onRegionClick} className={styles.region} id="guangdong">
          <path className={styles.path} fill="#D9FFFF" id="guangdong" fillRule="evenodd" d="
            M 1240.0000013113,1031.00000363588
            C 1240.99987632036,1033.66643148661 1242.00014925003,1036.3336109519 1243.00002425909,1038.99996364117
            C 1253.11049056053,1040.99264419079 1266.17691010237,1039.22078800201 1274.00002926588,1044.00000441074
            C 1275.33316278458,1051.33260583878 1276.66679370403,1058.66738694906 1278.00002670288,1065.99998837709
            C 1265.76456487179,1090.92292624712 1226.62579017878,1119.92389929295 1189.99998360872,1112.00000268221
            C 1186.5709027648,1104.70460617542 1184.88192874193,1095.42547333241 1182.99996322393,1086.00000113249
            C 1180.66685521603,1086.00000113249 1178.33315032721,1086.00000113249 1176.0000423193,1086.00000113249
            C 1166.19547748566,1090.98365670443 1170.62821358442,1098.98166251183 1165.00002449751,1107.99998509884
            C 1159.00057548285,1112.6662338376 1152.9994353056,1117.3338354826 1146.99998629093,1122.00000905991
            C 1144.14600020647,1126.71090370417 1144.35510748625,1134.10829430819 1142.00001436472,1138.00000423193
            C 1096.40358507633,1142.32216382027 1062.95258808136,1145.88902592659 1032.00003510714,1164.00000578165
            C 1032.33329361677,1165.66648560762 1032.66665160656,1167.3334915638 1033.00000959635,1168.99997138977
            C 1041.1283338666,1170.9751393199 1045.82091212273,1179.13083386421 1049.99997383356,1184.99996656179
            C 1049.99997383356,1185.99991458654 1049.99997383356,1187.00008809566 1049.99997383356,1188.00003612041
            C 1042.33413279057,1188.66656792164 1034.66590422392,1189.33340036869 1026.99996370077,1190.00000733137
            C 1022.00048917532,1185.00049269199 1016.99952244759,1179.99947482347 1012.00004792213,1175.00003534555
            C 1012.66656494141,1169.3339137435 1013.33338040113,1163.66606342793 1013.99999690056,1158.00001698732
            C 1017.54099291563,1156.88529741764 1039.58619254827,1146.02875107527 1044.00002741814,1142.99996984005
            C 1044.66664391756,1142.99996984005 1045.33335989714,1142.99996984005 1045.99997639656,1142.99996984005
            C 1047.33320939541,1140.00027608871 1048.66684031487,1136.99968039989 1049.99997383356,1133.99998664856
            C 1053.99957334995,1132.6667727232 1058.00036662817,1131.33318299055 1061.99996614456,1129.99996906519
            C 1064.33307415247,1125.33379548788 1066.66687852144,1120.66619384289 1068.99998652935,1116.00002026558
            C 1077.43381434679,1108.5934599638 1088.05769771338,1105.92725759745 1094.00004512072,1096.00000751019
            C 1092.96505361795,1090.33140558004 1090.45158809423,1088.83576774597 1091.00002217293,1084.99997794628
            C 1093.14978826046,1075.32023453712 1100.21675878763,1074.72172385454 1103.00001448393,1068.00003474951
            C 1106.64755374193,1059.19073617458 1103.52109152079,1050.98445796967 1107.00001192093,1042.99998122454
            C 1109.48910480738,1037.2872595191 1115.967849195,1037.77731221914 1118.00002974272,1029.00003242493
            C 1129.57951945066,1030.65020221472 1130.60287177563,1037.91959285736 1141.00003987551,1039.99998682737
            C 1148.43090879917,1032.86378228664 1142.38838487864,1027.32092547417 1143.99996334314,1016.00003165007
            C 1144.99993783236,1015.666690588 1146.00011128187,1015.33327436447 1146.99998629093,1015.00000846386
            C 1146.99998629093,1015.00000846386 1189.01871138811,1017.58390903473 1193.99998104572,1019.00002604723
            C 1193.99998104572,1019.33329194784 1193.99998104572,1019.66670817137 1193.99998104572,1019.99997407198
            C 1193.66672253609,1021.33318799734 1193.33326506615,1022.66677772999 1193.00000655651,1023.99999165535
            C 1186.48097270727,1026.01236450672 1183.02751922607,1029.55540043116 1180.0000397563,1035.00002121925
            C 1180.0000397563,1035.99989408255 1180.0000397563,1037.00006759167 1180.0000397563,1038.00001561642
            C 1180.99991476536,1039.33322954178 1182.00008821487,1040.66681927443 1182.99996322393,1042.00003319979
            C 1208.3595430851,1049.91258043051 1217.74221259356,1033.61118781567 1240.0000013113,1031.00000363588
            Z" />
          <text x="1120" y="1080" className={styles.label}>GUANGDONG</text>
        </g>
        <path fill="#eeeeee" id="hainan" fillRule="evenodd" d="
          M 1045.99997639656,1208.99999690056
          C 1051.74177181721,1208.73790889978 1056.89185929298,1208.91724413633 1060.00001716614,1210.99996811152
          C 1060.66663366556,1210.99996811152 1061.33344912529,1210.99996811152 1061.99996614456,1210.99996811152
          C 1060.17440587282,1220.34697175026 1055.81269860268,1239.01782929897 1052.99999678135,1250.9999936223
          C 1048.17013591528,1251.79753184319 1046.70847404003,1248.14724051952 1041.99997895956,1249.00002241135
          C 1032.27758473158,1250.76082986593 1031.38992333412,1259.54720419645 1013.00002241135,1258.00000560284
          C 1010.38220220804,1247.10445046425 1000.95745259523,1250.56556040049 991.999961256981,1247.99999922514
          C 991.999961256981,1243.33382564783 991.999961256981,1238.66622400284 991.999961256981,1233.99997526407
          C 996.770134031773,1227.6706289649 1008.73172700405,1227.67107993364 1016.00004535913,1223.99996888638
          C 1016.33330386877,1221.00027513504 1016.66666185856,1217.99967944622 1017.00001984835,1214.99998569489
          C 1029.20245426893,1215.43464440107 1038.74638110399,1213.66677176952 1045.99997639656,1208.99999690056
          Z" />
      </svg>
    )
  }
}

export default MapSVG
