import React from 'react'
import styles from './flavor-profile.module.css'

const gray = '#d8d8d8';

const Flavor = ({ degrees, name, tier }) => (
  <svg className={styles.flavor} viewBox="0 0 224 224" width="224" height="224" transform={`rotate(${degrees}) scale(0.8)`}>
    <path
      fill={tier > 4 ? '#9d0b0b' : gray}
      d=" M 65 33 A 92 92 -121 0 1 159 33
          L 170 16
          M 170 16 A 112 112 -59 0 0 54 16
          L 65 33" />
    <path
      fill={tier > 3 ? '#da2d2d' : gray}
      d=" M 148 52 A 70 70 -59 0 0 76 52
          L 66 36
          M 66 36 A 89 89 -121 0 1 158 36
          L 148 52" />
    <path
      fill={tier > 2 ? '#eb8242' : gray}
      d=" M 88 72 A 47 47 -121 0 1 136 72
          L 146 55
          M 146 55 A 66 66 -59 0 0 78 55
          L 88 72" />
    <path
      fill={tier > 1 ? '#f6da63' : gray}
      d=" M 100 92 A 23 23 -121 0 1 124 92
          L 134 75
          M 134 75 A 43 43 -59 0 0 90 75
          L 100 92" />
    <path
      fill={tier > 0 ? '#F8E38C' : gray}
      d=" M 112 112
          M 122 95 A 20 20 -59 0 0 102 95
          L 112 112" />
    <text x="50%" y="-15" text-anchor="middle">{name}</text>
  </svg>
)

const FlavorProfile = ({ umami, bitter, sweet, sour, spice }) => (
  <div transform="scale(.9)">
    <Flavor degrees={0} name="umami" tier={umami} />
    <Flavor degrees={72} name="sweet" tier={sweet} />
    <Flavor degrees={144} name="sour" tier={sour} />
    <Flavor degrees={216} name="bitter" tier={bitter} />
    <Flavor degrees={288} name="spice" tier={spice} />
  </div>
)

export default FlavorProfile;
