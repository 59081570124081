import React from 'react'

import FlavorProfile from './flavor-profile'
import RichText from './rich-text'
import RecipePreview from './recipe-preview'

import styles from './region-info.module.css'

export default ({ region }) => (
  <article className={styles.region}>
    <section className={styles.header}>
      <h1>{region.nameEnglish} • {region.nameChinese}</h1>
      <p className={styles.desc}>{region.shortDescription && region.shortDescription.shortDescription}</p>
    </section>

    <section className={styles.profile}>
      <section className={styles.flavorProfile}>
        <h2>Flavor Profile</h2>
        <FlavorProfile
          umami={region.umami}
          bitter={region.bitter}
          sweet={region.sweet}
          sour={region.sour}
          spice={region.spice}
        />
      </section>
      {region.keyIngredients &&
        <section className={styles.keyIngredients}>
          <h2>Key Ingredients</h2>
          <ul className={styles.list}>
            {region.keyIngredients.map(ing => (
              <li>
                <div className={styles.ingredientImage} />{ing}
              </li>
            ))}
          </ul>
        </section>
      }

      {region.keyTechniques &&
        <section className={styles.keyTechniques}>
          <h2>Techniques</h2>
          <ul className={styles.list}>
            {region.keyTechniques.map(tech => (
              <li>
                <div className={styles.techniqueImage} />{tech}
              </li>
            ))}
          </ul>
        </section>
      }
    </section>

    {region.recipes &&
      <section className={styles.recipes}>
        <h2>Recipes</h2>
        <section className={styles.recipePreviews}>
          {region.recipes.map(recipe => (
            <RecipePreview key={recipe.slug} recipe={recipe} />
          ))}
        </section>
      </section>
    }

    {region.didYouKnow &&
      <section className={styles.didYouKnow}>
        <h2>Did You Know?</h2>
        <RichText field={region.didYouKnow} />
      </section>
    }
  </article>
)
